import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../../../components/common/Footer';
import GoBack from '../../../components/common/GoBack';
import Loading from '../../../components/common/Loading';
import RedirectTo from '../../../components/common/RedirectTo';
import WaveSeparator from '../../../components/common/WaveSeparator';
import CustomHead from '../../../components/CustomHead';
import ToggleInput from '../../../components/Posts/ToggleInput';
import { RootState } from '../../../state';
import { enablePost, getSinglePost } from '../../../state/action-creators/post';
import { getSettings } from '../../../state/action-creators/settings';
import { AuthState } from '../../../state/actions/auth';
import { PostState } from '../../../state/actions/post';
import { SettingsState } from '../../../state/actions/settings';


interface Image {
  original: string,
  thumbnail: string,
  embedUrl?: string
}

const ViewPost = () => {
  const {id} = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {post, loading} = useSelector((state: RootState) => state.post) as PostState;
  const {user, isAuthenticated, loading: userLoading, token} = useSelector((state: RootState) => state.auth) as AuthState;
  const [images, setImages] = useState<Image[] | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const {settings} = useSelector((state: RootState) => state.settings) as SettingsState;

  useEffect(() => {
    const retrieveSettings = () => dispatch(getSettings() as any);
    retrieveSettings();
  },[dispatch]);
  useEffect(() => {
    if(id){
      const retrievePost = () => dispatch(getSinglePost(id) as any);
      retrievePost();
    }
  },[dispatch, id, loading]);
  useEffect(() => {
    if(post){
      console.log("post.enabled: ",post.enabled);
      setIsChecked(post.enabled!)
    }
  },[post, loading])
  useEffect(() => {
    let parsedVideos;
    if (post && post.videos && post.videos.length > 0) {
      parsedVideos = post.videos.map(url => {
        const videoId = url.split("v=")[1];
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        return embedUrl;
      });
    }
    const multimedia = post?.images?.concat(parsedVideos || []);
    if (!loading && multimedia && multimedia.length > 0) {
      const formattedData = multimedia.map(item => {
        item = item.toLowerCase();
        if (item.endsWith(".png") || item.endsWith(".jpg") || item.endsWith(".jpeg")) {
          return { original: `${item}`, thumbnail: `${item}` };
        } else if (item.includes("youtube.com")) {
          const videoId = item.split("/").slice(-1)[0];
          return { original: item, thumbnail: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`, embedUrl: videoId };
        } else {
          return null;
        }
      }).filter(item => item !== null) as Image[];
      console.log("formattedData: ",formattedData);
      setImages(formattedData);
    } else {
      setImages(null);
    }
    return () => {
      setImages(null);
    }
  }, [loading, post]);
  const renderItem = (item: ReactImageGalleryItem | any) => {
    if (item.original && !item.embedUrl) {
      return (
        <img src={item.original} alt={item.original} className="w-full h-600 object-fill"/>
      );
    } else if (item.embedUrl) {
      return (
        <iframe title={item.original} width="100%" src={item.original} frameBorder='0' allow="autoplay; encrypted-media" />
      );
    } else {
      return null;
    }
  };
  const handleDownloadPDF = async (pdfurl: string, name: string) => {
    try {
      const response = await axios.get(`${pdfurl}`, {
        responseType: 'blob', // Set the response type to 'blob' to handle binary data
      });
      // Create a temporary URL for the PDF blob
      const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
  
      // Create a link element and simulate a click to download the PDF
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
      
      // Release the URL object to free up memory
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };
  const handleToggle = (event: any) => {
    setIsChecked(event.target.checked);
    dispatch(enablePost(id!, event.target.checked) as any);
  };
  // console.log("location: ",location.pathname);
  return (
    loading ? <Loading/> :
    <div className='view-post bg-grey flex flex-col justify-between'>
      <CustomHead title={`Revuelo Films - ${post?.title}`}/>
      <div className="relative h-inherit wrapper-banner ">
        <div className='fixed z-10 flex items-center'>
          {
            location.pathname.includes('admin') && 
            <>
              <GoBack url='/admin/posts' isFloating={true}/>
              <ToggleInput handleToggle={handleToggle} isChecked={isChecked}/>
            </>
          }
        </div>
        <div className='w-full img-banner bg-white h-fit' style={{backgroundImage: `url(${settings?.proyectoindividualbackground})`}}>
          <div className="w-full h-fit flex items-center justify-center mb-0">
            <div className="py-48 pb-32 px-8 w-full md:w-3/4 max-w-1xl">
              <h1 className='font-berlin text-3xl md:text-4xl lg:text-5xl header-title mb-4 text-white text-center'>
                {post?.title}
              </h1>
              {
                images && images.length > 0 && 
                <ImageGallery items={images} 
                  showThumbnails={true} 
                  renderItem={renderItem} 
                  thumbnailPosition="bottom" 
                  infinite 
                  showPlayButton={false}
                  />
              }
            </div>
          </div>
          <WaveSeparator/>
        </div>
      </div> 
      <div className='w-full h-full lg:w-3/4 flex items-start mx-auto'>
        <div className='m-5 py-0 md:py-12 lg:px-20 px-0 md:px-8 w-auto text-lg leading-5 lg:text-2xl font-nova lg:leading-7 content-body-post'>
          {
            (post?.description && !loading) && 
            <div 
            className='' dangerouslySetInnerHTML={{ 
              __html: post.description
            }}/>
          }
          <div className='my-4'>
            {
              post?.pdfdocument && post?.pdfdocument.length > 0 && 
              <RedirectTo text='Descargar Programa de Mano' onClick={() => handleDownloadPDF(post.pdfdocument!, post.title)} icon={<FontAwesomeIcon icon={faDownload}/>}/>
            }
          </div>
        </div>
      </div>
      <Footer isWhite={false}/>
    </div>
  )
}

export default ViewPost