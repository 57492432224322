import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import GoBack from '../../../components/common/GoBack';
import Loading from '../../../components/common/Loading';
import CustomHead from '../../../components/CustomHead';
import { RootState } from '../../../state';
import { getSingleContact } from '../../../state/action-creators/contact';
import { Contact, ContactState } from '../../../state/actions/contact';
import Message from './Message';

const ContactoMessage = () => {
  const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm<any>();
  const [formDisabled, setFormDisabled] = useState(false);
  const [message, setMessage] = useState<Contact>();
  const {contact, loading} = useSelector((state: RootState) => state.contact) as ContactState;
  const {id} = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const retrieveSingleMessage = (id: string) => dispatch(getSingleContact(id) as any);
    retrieveSingleMessage(id!);
  },[dispatch])
  useEffect(() => {
    if (!loading){
      setMessage(contact!);
    }
  },[contact, loading])
  
  console.log("contact: ",message);
  return (
    <div className="p-4 md:p-8 flex flex-col lg:w-3/4 sm:w-full w-full m-auto h-screen md:h-auto">
      <CustomHead title="Revuelo - Mensaje"/>
      <div className="flex items-center gap-x-5 mb-2 w-full justify-between">
        <GoBack url={'/admin/contacto'}/>
        <h1 className="text-lg md:text-2xl text-gray-800 font-medium">Revuelo - Mensaje</h1>
      </div>
      {/* <form method="post" onSubmit={handleSubmit((data) => handleOnSubmit(data, setFormDisabled))} className="border p-4 md:p-8 rounded-md self-center w-full bg-gray-50 shadow-md" aria-disabled={formDisabled}> */}
      {
        loading ? <Loading/> :
        message?.fullname && message?.fullname.length > 0 ?
        <Message {...message!}/> : <></> 
      }
      {
        // message?.fullname && message?.fullname.length > 0 &&
        // <Message {...message}/> 
      }
      {/* </form> */}
    </div>
  )
}

export default ContactoMessage