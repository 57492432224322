import { faEdit, faEye, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import jsPDF from 'jspdf';
import React from 'react';
import 'react-awesome-slider/dist/styles.css';
import { useDispatch } from 'react-redux';
import { removePost } from '../../state/action-creators/post';
import { Post } from '../../state/actions/post';
import ButtonIcon from '../common/ButtonIcon';



export interface CardProps {
  _id: string
  title: string,
  previewDesc: string,
  // description: string,
  images: Array<string>,
  qrcode: string,
  videos: Array<string>,
  enabled: boolean,
  createdAt: Date,
}

interface IExportPDF{
  title: string,
  qrcode: string
}

const ArticleCard = ({ _id, title, previewDesc, images = [], videos, qrcode }: Post) => {
  const options = {
    // type         : 'loop',
    autoplay     : true,
    pauseOnHover : false,
    resetProgress: false,
  };
  const dispatch = useDispatch();
  function handleExportPDF({qrcode, title}: IExportPDF){
    const doc = new jsPDF();
    const margins = { top: 10, bottom: 10, left: 10, right: 10 };
    //Setting Background
    doc.setFillColor(221,185,124); // set the fill color
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), 50, "F"); // draw the rectangle
    doc.addImage('/logos/revuelo-original.png', "JPEG", 5, 0, 50, 50);
    const docWidth = doc.internal.pageSize.getWidth() - margins.left - margins.right;
    const docHeight = doc.internal.pageSize.getHeight();
    const splitDescription = doc.splitTextToSize(title, docWidth - 60);
    // doc.text(splitDescription, 60, 20, { align: "left" });
    doc.setTextColor("#222");
    doc.text(splitDescription, 60, 20, { align: "left", lineHeightFactor: 1.2 });
    doc.setDrawColor(255, 255, 255); // set the line color
    // doc.line(0, 50, doc.internal.pageSize.getWidth(), 50);

    doc.setFontSize(20);
    const qrWidth = 100; // set the width of the image
    const qrX = (doc.internal.pageSize.getWidth() - qrWidth) / 2; // calculate the X coordinate
    const qrY = 100; // set the Y coordinate of the image
    const text = "Escanea este código QR y descubre la magia de Revuelo Arts";
    const textX = qrX + qrWidth / 2; // calculate the center position of the QR code
    doc.text(text, textX, qrY - 10, { align: "center" });
    doc.addImage(qrcode, 'JPEG', qrX, qrY, qrWidth, qrWidth);
    
    doc.save(`${title}.pdf`);
    console.log("export!");
  }
  function handleDelete(evt: any, id: string){
    evt.preventDefault();
    if (window.confirm("¿Estás seguro de eliminar este proyecto?")) {
      dispatch(removePost(id) as any)
    }
  }
  let parsedVideos = videos && videos.length > 0 && videos.map(url => {
    const videoId = url.split("v=")[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  }) || [];
  
  let multimedia = images.concat(parsedVideos);
  const hasNonEmptyString = multimedia && multimedia.length > 0 && multimedia.some(image => image !== "");
  const pattern = /\.(jpg|jpeg|png|svg)$/i;

  return (
    <article className="bg-white rounded-md drop-shadow-md flex flex-col justify-between">
      {
        hasNonEmptyString ? 
        <Splide options={options}>
          {
            multimedia && multimedia.length > 0 && multimedia.map((image, idx) => (
              <SplideSlide key={idx}>
                {
                  (pattern.test(image)) ? 
                  <img src={`${image}`} alt={`Image ${idx}`} className="border border-y-0" style={{width: "100%",height:"200px", objectFit:"fill", borderRadius:"0.375rem 0.375rem 0 0"}} />
                  : 
                  <iframe title={image} width="100%" height="200px" src={image} frameBorder="0"></iframe>
                }
              </SplideSlide>
              
            ))
          }
        </Splide> :
        <img src={`/images/empty.jpeg`} alt={`No Image`} className="border border-y-0" style={{width: "100%",height:"200px", objectFit:"fill", borderRadius:"0.375rem 0.375rem 0 0"}} />
      }
      <div className='px-3 py-3 border border-y-0 h-full min-card-content'>
        <p className="m-0 leading-normal text-gray-600 font-semibold text-md leading-tight">
          {title}
        </p>
        <p className="mt-1 m-0 leading-normal line-clamp-3 text-sm text-gray-600 leading-5 font-normal">
          {previewDesc || ""}
        </p>
      </div>
      <div className="p-3 grid grid-cols-4 md:grid-cols-4 gap-2 w-full bg-slate-50 border rounded-md rounded-t-none">
        <ButtonIcon icon={<FontAwesomeIcon icon={faEdit} size='lg'/>} url={`/admin/posts/edit/${_id}`}/>
        <ButtonIcon icon={<FontAwesomeIcon icon={faFilePdf} size='lg'/>} onClick={() => handleExportPDF({qrcode: qrcode!, title})} classes="text-white bg-gray-800 hover:bg-gray-700"/>
        <ButtonIcon icon={<FontAwesomeIcon icon={faEye} size='lg'/>} url={`/admin/posts/view/${_id}`} classes="bg-gray-800 hover:bg-gray-700 text-white"/>
        <ButtonIcon icon={<FontAwesomeIcon icon={faTrash} size='lg'/>} onClick={(evt) => handleDelete(evt, _id!)} classes="text-white bg-red-700 hover:bg-red-800"/>
      </div>
    </article>
  )
}

export default ArticleCard