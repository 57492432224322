import React from 'react'
import Loading from './Loading'

interface IButton {
  type?: 'button' | 'submit' | 'reset',
  label: string,
  icon?: JSX.Element,
  isLoading?: boolean
}
const Button = ({type = 'button', label, icon, isLoading = false}: IButton) => {
  return (
    <>
      <button type={type} className={`w-full text-center lg:text-left xl:w-3/12 justify-center inline-block py-4 px-5 bg-gray-800 text-white font-normal text-sm leading-tight rounded shadow-md transition duration-150 ease-in-out text-base leading-none text-white flex items-center mb-2 ${(isLoading) ? 'cursor-not-allowed bg-gray-400' : 'cursor-pointer hover:bg-gray-900 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg'}`}>
        {
          (icon && !isLoading) && <span className='mr-2'> {icon} </span>
        }
        {
          (isLoading) && <Loading isButton={true}/>
        }
        {label}
      </button>    
    </>
  )
}

export default Button