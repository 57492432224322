import { combineReducers } from 'redux';
import aboutReducer from './about';
import alertReducer from './alert';
import authReducer from './auth';
import contactReducer from './contact';
import orderReducer from './order';
import popupReducer from './popup';
import postReducer from './post';
import productReducer from './product';
import settingsReducer from './settings';
import userReducer from './user';

const reducers = combineReducers({
  products: productReducer,
  auth: authReducer,
  orders: orderReducer,
  alerts: alertReducer,
  popup: popupReducer,
  post: postReducer,
  user: userReducer,
  about: aboutReducer,
  settings: settingsReducer,
  contact: contactReducer
})

export default reducers;
export type State = ReturnType<typeof reducers>