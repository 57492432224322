import React from 'react';
import { FieldValues, Path, RegisterOptions } from "react-hook-form";

interface IInput<T extends FieldValues>{
  name: Path<T>;
  registerOptions?: RegisterOptions<T>;
  register: any,
  label: string,
  errors: any,
  value?: string,
  showRequired?: boolean
}

const TextArea = <T extends Record<string, unknown>>({label, name, registerOptions, register, errors, value, showRequired}: IInput<T>) => {
  // const { handleSubmit, register, watch, setValue } = useForm<T>()
  return (
    <div className="mb-6">
      <div className='w-full flex justify-between items-center'>
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">
          {label} {(registerOptions?.required && showRequired) && <span className='text-gray-900 dark:text-gray-500'>(Requerido)</span>
        }
        </label>
        <span className='text-gray-900 dark:text-gray-500 text-sm'>{value?.length || 0} Caracteres</span>
      </div>
      <textarea id={name} rows={6} className="shadow-sm bg-white border border-border text-gray-900 text-md rounded-lg focus:ring-gray-800 focus:border-gray-800 focus:outline-none block w-full p-2.5" {...register(name, registerOptions)}>
      </textarea>
      {(errors[name]) ? 
        <label className='text-red-400'>{errors[name]?.message || ''}</label> : ''}
    </div>
  )
}

export default TextArea