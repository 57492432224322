import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { url } from "../../utils/constants";
import { UserType } from "../action-types/users";
import { Action } from "../actions";
import { User, UserAction } from "../actions/user";
import { setAlert } from "./alert";

interface UserResponse {
  ok: boolean,
  data: Array<User>
}
interface SingleUserResponse {
  ok: boolean,
  data: User
}
interface DataSingleUser{
  data: SingleUserResponse
}
interface Data{
  data: UserResponse
}

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
}
export const getUsers = () => async(dispatch: Dispatch<UserAction | Action>) => {
  dispatch({
    type: UserType.CLEAR_USERS,
  })
  try {
    const {data: response}: Data  = await axios.get(`${url}/user`, config);
    console.log("response: ",response)
    dispatch({
      type: UserType.RETRIEVE_ALL_USERS,
      payload: response.data
    })
  } catch (err) {
    let error = err as AxiosError;
    if(error.response) {
      dispatch({
        type: UserType.ERROR_USER,
        payload: {
          msg: error.response.data as string,
          status: error.response.status
        }
      })
    }
  }
}

export const getSingleUser = (id: string) => async (dispatch: Dispatch<UserAction | Action>) => {
  try {
    const {data: response}: DataSingleUser = await axios.get(`${url}/user/${id}`, config);
    console.log("single-user: ",response);
    dispatch({
      type: UserType.RETRIEVE_SINGLE_USER,
      payload: response.data
    })
  } catch (err) {
    let error = err as AxiosError;
    if (error.response) {
      dispatch({
        type: UserType.ERROR_USER,
        payload: {
          msg: error.response.data as string,
          status: error.response.status
        }
      })
    }
  }
}

export const addUser = (formData: User) => async(dispatch: Dispatch<UserAction | Action | any>) => {
  try {
    const {data: response}: Data = await axios.post(`${url}/user`,formData ,config);
    console.log("res: ",response);
    dispatch({
      type: UserType.ADD_USER,
      payload: response.data
    })
    dispatch(setAlert("Usuario Agregado", "success"));
  } catch (err) {
    let error = err as AxiosError | any;
    console.log("error: ",error.response.data);
    // const errors = error?.response?.data.msg;
    // if(errors){
      // error.forEach((error: any) => dispatch(setAlert(error.response.data.msg, 'error') as any));
    // }
    dispatch(setAlert(error.response.data.msg, 'error') as any);
    if(error.response) {
      dispatch({
        type: UserType.ERROR_USER,
        payload: {
          msg: error.response.data,
          status: error.response.status
        }
      })
    }
  }
}

export const updateUser = (id: string, formData: User) => async(dispatch: Dispatch<UserAction | Action | any>) => {
  try {
    let {data:response}: Data = await axios.put(`${url}/user/${id}`,formData ,config);
    console.log("response: ",response)
    dispatch({
      type: UserType.EDIT_USER,
      payload: response.data,
    })
    dispatch(setAlert("Usuario actualizado", "warning"));
  } catch (err) {
    let error = err as AxiosError | any;
    if (error.response){
      console.log("error: ",error.response.data.msg)
      dispatch(setAlert(error.response.data.msg, "error"));
      dispatch({
        type: UserType.ERROR_USER,
        payload: {
          msg: error.response.data,
          status: error.response.status
        }
      })
    }
  }
}

export const removeUser = (id: string) => async(dispatch: Dispatch<UserAction | Action | any>) => {
  try {
    await axios.delete(`${url}/user/${id}`,config);
    dispatch({
      type:UserType.DELETE_USER,
      payload: id
    });
    dispatch(setAlert("Proyecto eliminado", "error"));
  } catch (err) {
    let error = err as AxiosError;
    if(error.response) {
      dispatch({
        type: UserType.ERROR_USER,
        payload: {
          msg: error.response.data,
          status: error.response.status
        }
      })
    }
  }
}

export const enableUser = (id: string, enabled: boolean) => async (dispatch: Dispatch<UserAction | Action | any>) => {
  try {
    const {data: response}: DataSingleUser = await axios.put(`${url}/user/enable/${id}`, {enabled}, config);
    if (enabled) {
      dispatch(setAlert("Usuario habilitado", "success"));
    }else{
      dispatch(setAlert("Usuario deshabilitado", "warning"));
    }
    dispatch({
      type: UserType.ENABLE_USER,
      payload: response.data
    })
  } catch (err) {
    let error = err as AxiosError;
    if (error.response) {
      dispatch({
        type: UserType.ERROR_USER,
        payload: {
          msg: error.response.data as string,
          status: error.response.status
        }
      })
    }
  }
}