
import { faAddressBook, faBars, faBuilding, faClose, faGear, faHouse, faNewspaper, faSignOut, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../state';
import { loadUser, logout } from '../../state/action-creators/auth';
import { AuthState } from '../../state/actions/auth';
import Loading from '../common/Loading';

type Props = {
  // children?: JSX.Element | JSX.Element[];
  children: any
};

interface ListItem {
  href: string;
  title: string,
  icon: JSX.Element,
  section: string,
}

export default function Sidebar({children}: Props) {
  // const navigation = useNavigation();
  const router = useLocation();
  const {user, loading: loadingUser} = useSelector((state: RootState) => state.auth) as AuthState;
  const [active, setActive] = useState(false)
  const [navbar, setNavbar] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<ListItem | null>({
    href: '/',
    section: '',
    title: 'Home',
    icon: <FontAwesomeIcon icon={faHouse} />
  })
  const dispatch = useDispatch();
  const navigate = useNavigate()


  console.log("user: ",user);
  const menuItems = {
    'isadmin': [
      {
        href: '/admin/posts',
        title: 'Proyectos',
        icon: <FontAwesomeIcon icon={faNewspaper} className="w-5 h-5 fill-current dark:text-gray-400"/>
      },
      {
        href: '/admin/users',
        title: 'Usuarios',
        icon: <FontAwesomeIcon icon={faUsers} className="w-5 h-5 fill-current dark:text-gray-400"/>
      },
      {
        href: '/admin/nosotros',
        title: 'Nosotros',
        icon: <FontAwesomeIcon icon={faBuilding} className="w-5 h-5 fill-current dark:text-gray-400"/>
      },
      {
        href: '/admin/contacto',
        title: 'Contacto',
        icon: <FontAwesomeIcon icon={faAddressBook} className="w-5 h-5 fill-current dark:text-gray-400"/>
      },
      {
        href: '/admin/configuracion',
        title: 'Configuración',
        icon: <FontAwesomeIcon icon={faGear} className="w-5 h-5 fill-current dark:text-gray-400"/>
      }
    ],
    'isnotadmin': [
      {
        href: '/admin/posts',
        title: 'Proyectos',
        icon: <FontAwesomeIcon icon={faNewspaper} className="w-5 h-5 fill-current dark:text-gray-400"/>
      },
    ]
  } as any;


  
  const handleRemove = () => {
    // cookies.remove("OursiteJWT", { path: "/" })
    console.log("removed!!");
  }
  const handleLogout = () => {
    navigate("/admin/login");
    dispatch(logout() as any);
  }
  
  useEffect(() => {
    dispatch(loadUser() as any);
  },[])
  // console.log("user: ",user);
  return (
    <div className='min-h-screen flex flex-col relative'>
      <div className='flex flex-col md:flex-row flex-1 relative'>
        <aside className={`bg-gray-900 w-full md:w-60 h-auto md:h-screen flex flex-col justify-between sticky top-0 md:top-0 z-10 md:flex ${navbar ? "flex" : "hidden"}`}>
          {/* {active && <FontAwesomeIcon icon={faAngleLeft} onClick={showLess} className='absolute hidden text-sm text-white cursor-pointer -right-3 top-10 z-20 bg-gray-500 py-1 px-2 rounded-full group-hover:block transition duration-300 hover:bg-gray-600' />}
          {!active && <FontAwesomeIcon icon={faAngleRight} onClick={showMore} className='absolute text-sm text-white cursor-pointer -right-3 top-10 bg-gray-500 py-1 px-2 rounded-full transition duration-300 hover:bg-gray-600' />} */}
          <nav className='divide-y divide-gray-700'>
            <button className="p-2 text-white absolute right-0 top-0 m-3 md:invisible" onClick={() => setNavbar(!navbar)}>
              {navbar && (
                  <FontAwesomeIcon icon={faClose} size="2x" />
              )}
            </button>
            <ul className='space-y-1 text-sm border-none bg-gray-900'>
              {
                loadingUser ? <Loading/> :
                <li className="py-5 px-2 dark:text-gray-50 flex items-center p-2 space-x-2 divide-y-5">
                  <div className='relative'>
                    <img src={user?.profilephoto} alt="" className="w-12 h-12 rounded-full object-contain"/>
                  </div>
                  <div>
                    <h2 className="text-md font-semibold leading-5 mb-0">{user?.fullname}</h2>
                    <span className="flex items-center space-x-1">
                      <Link to={`#`} className="text-xs hover:underline dark:text-gray-400">
                        Ver Perfil
                      </Link>
                    </span>
                  </div>
                </li>
              }
            </ul>
            <ul className='pt-2 text-sm'>
              {menuItems[user && user?.isadmin ? 'isadmin' : 'isnotadmin'].map(({ href, title, icon }: any, index: number) => (
                <li className='m-2 dark:text-gray-50' key={title}>
                  <Link to={href} className={`flex space-x-3 p-2 bg-white-100 rounded hover:bg-gray-800 cursor-pointer transition duration-300 ${
                        router.pathname.includes(href) && 'bg-gray-600 text-gray-100'
                      }`}
                    >
                      <span className='min-w-20'>
                      {icon}
                      </span>
                      <span>{title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <nav className='divide-y divide-gray-700'>
            <ul className='space-y-1 text-sm border-none bg-gray-900'>
              <li className='m-2 dark:text-gray-50'>
                <button className={`w-full flex space-x-3 p-2 bg-white-100 rounded hover:bg-gray-800 cursor-pointer items-center`} onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOut} className='min-w-20'/>
                    <span>Cerrar Sesión</span>
                </button>
              </li>
            </ul>
          </nav>
        </aside>
        <div className='flex-1 bg-grey relative'>
          <header className={`z-10 bg-gray-800 dark:text-gray-100 h-auto w-full flex flex-row-reverse justify-between md:flex-row md:justify-center items-center font-semibold px-5 md:p-2 sticky top-0 min-h-60 ${navbar ? 'hidden' : ''}`}>
                {
                  (!navbar) && (
                    <button className="justify-self-end md:hidden" onClick={() => setNavbar(!navbar)}>
                    <FontAwesomeIcon icon={faBars} size='2x'/>
                    </button>
                  )
                }
              <h1 className='revuelo-text-name'>
                Revuelo
                <span>Arts</span>
              </h1>
          </header>
          <main>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}