import { Splide, SplideSlide } from '@splidejs/react-splide'
import { format } from 'date-fns'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Post } from '../../state/actions/post'


export interface CardProps {
  id?: string,
  title: string,
  previewDesc: string,
  body: string,
  images: Array<string>,
  createdAt?: Date
}

const MainCard = ({ title, previewDesc, images = [], createdAt, _id, videos}: Post): JSX.Element => {
  const options = {
    type         : 'loop',
    autoplay     : false,
    pauseOnHover : false,
    resetProgress: false,
  };
  let parsedVideos = videos && videos.length > 0 && videos.map(url => {
    const videoId = url.split("v=")[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  }) || [];
  
  let multimedia = images.concat(parsedVideos);
  const hasNonEmptyString = multimedia && multimedia.length > 0 && multimedia.some(image => image !== "");
  const pattern = /\.(jpg|jpeg|png|svg)$/i;
  const navigate = useNavigate();
  const handleRedirect = (id: string, e:any) => {
    e.stopPropagation();
    navigate(`/proyectos/${id}`);
  }
  console.log("multimedia: ",multimedia);
  return (
    <div className="w-full lg:flex font-nova h-auto lg:h-96 lg:col-span-3 group">
      <div className="md:max-w-md lg:max-w-lg xl:max-w-2xl h-48 lg:h-auto flex-none bg-cover rounded-t-md lg:rounded-t-none lg:rounded-l-md text-center overflow-hidden relative inline-block m-0 w-full">
        {
        hasNonEmptyString ? 
          <Splide options={{
            ...options
          }}>
          <>
            {
              multimedia && multimedia.length > 0 && multimedia.map((image, idx) => (
                <SplideSlide key={idx}>
                {/* <> */}
                  {
                    (pattern.test(image)) ? 
                    // <img src={`${image}`} alt={`Image ${idx}`} className="object-fit block absolute top-1/2 left-1/2 min-h-full min-w-full -translate-x-1/2 -translate-y-1/2 w-full transition duration-500 group-hover:scale-110" />
                    <img src={`${image}`} alt={`Image ${idx}`} className="object-fit w-full h-full overflow-hidden flex "/>
                    : 
                    <iframe title={image} width="100%" height="384px" src={image} frameBorder="0"></iframe>
                  }
                {/* </> */}
                </SplideSlide>
              ))
            }
          </>
          </Splide> 
          :
          <img src={`/images/empty.jpeg`} alt={`No Image`} className="border border-y-0" style={{width: "100%",height:"200px", objectFit:"fill", borderRadius:"0.375rem 0.375rem 0 0"}} />
        }

        {/* <img src={images && images.length > 0 ? images[0] : 'https://revueloarts.com/images/empty.jpeg'} alt="" className='block absolute top-1/2	left-1/2 min-h-full min-w-full -translate-x-1/2 -translate-y-1/2 w-full transition duration-500 group-hover:scale-110' /> */}
      </div>
      <div className="rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal md:max-w-md lg:max-w-lg xl:max-w-2xl py-0 lg:px-4 cursor-pointer" onClick={(e) => handleRedirect(_id!, e)}>
        <div className="mb-8">
          <p className="text-sm lg:text-md text-slate-400 flex items-center">
            {format(new Date(createdAt!), "MMMM dd, yyyy")}
          </p>
          <div className="text-black font-nova-semibold text-xl lg:text-2xl mb-1">{title}</div>
          <p className="text-grey-darker text-base lg:text-lg">{previewDesc}</p>
        </div>
      </div>
    </div>
  )
}

export default MainCard