// import FileUpload from "../../../components/admin/FileUpload";
// import CustomHead from "../../../components/CustomHead";
// import Editor from "../../../components/EditorText";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import Button from '../../../components/common/Button';
import FileUpload from "../../../components/common/FileUpload";
import GoBack from "../../../components/common/GoBack";
import Input from '../../../components/common/Input';
import Loading from '../../../components/common/Loading';
import CustomHead from "../../../components/CustomHead";
import { RootState } from '../../../state';
import { getSingleUser, updateUser } from '../../../state/action-creators/user';
import { User, UserState } from '../../../state/actions/user';
import { url } from '../../../utils/constants';
import { getGravatarUrl } from '../../../utils/helpers';

function EditUser(){
  const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm<User>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const {user, loading} = useSelector((state: RootState) => state.user) as UserState;
  const navigate = useNavigate();
  const {id} = useParams();
  const [formDisabled, setFormDisabled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [profilephoto, setProfilePhoto] = useState<{images: File[]}>({
    images: []
  });
  const dispatch = useDispatch();

  
  async function handleOnSubmit(data: User, setFormDisabled: (disabled: boolean) => void){
    try {
      setFormDisabled(true); // Disable the form
      const uploadedData = await uploadFileHandler(); // Wait for uploadFileHandler to complete and get the data
      // if((isYoutubeUrlValid.length > 0 && isYoutubeUrlValid.some(url => url === false))) return;
      
      let data_to_send = {
        fullname: data.fullname,
        email: data.email,
        password: data.password.length > 0 ? data.password : "",
        profilephoto: uploadedData[0] || getGravatarUrl(data.email),
        isadmin: isChecked
      }
      console.log("data_to_send: ",data_to_send);
      // return;
      if(!uploading){
        dispatch(updateUser(id!, data_to_send) as any);
      }
      navigate('/admin/users')
    }catch(error){
      console.log("Error: ",error);
    } finally {
      setFormDisabled(false); // Re-enable the form
    }
  }
  const updateUploadedFiles = (files: Array<File>) => setProfilePhoto({ ...profilephoto, images: files });
  
  const uploadFileHandler = async () => {    
    const files = [...profilephoto.images];
    if (files) {
      const formData = new FormData();
      files.forEach(function(file){
        formData.append("image", file);
      })
      setUploading(true);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post(`${url}/upload`, formData, config);
        // setValue("images", data, { shouldValidate: true, shouldDirty: true });
        console.log("loaded-image: ",data);
        // setValue('images',{...data})
        // setValue('profilephoto', data);
        setUploading(false);
        return data;
      } catch (error) {
        setUploading(false);
      }
    }
  };
  const handleToggle = (event: any) => {
    setIsChecked(event.target.checked);
  };
  useEffect(() => {
    if (!loading){
        setValue('fullname', user?.fullname || "");
        setValue('email', user?.email || "");
        setValue('profilephoto', user?.profilephoto || []);
        if(user && user.isadmin){
          setIsChecked(user?.isadmin || false);
        }
    }
  },[user, loading])
  useEffect(() => {
    if(user && user.profilephoto && user.profilephoto.length > 0){
      let images = user.profilephoto.map(async function(image: string){
        const response = await fetch(`${image}`);
        var blob = await response.blob();
        return new File([blob], image, { type: blob.type });
      }) || []
      Promise.all(images).then((res) => updateUploadedFiles(res));
    }
  }, [user, loading])
  useEffect(() => {
    if(id){
      const retrieveUser = () => dispatch(getSingleUser(id) as any);
      retrieveUser();
    }
  },[id])

  console.log("user: ",user)
  return (
    loading ? <Loading/> :
    <div className="p-4 md:p-8 flex flex-col lg:w-3/4 sm:w-full w-full m-auto">
      <CustomHead title='Revuelo Admin - Crear Proyecto'/>
      <div className="flex items-center gap-x-5 mb-2 w-full justify-between">
        <GoBack url={'/admin/users'}/>
        <h1 className="text-lg md:text-2xl text-gray-800 font-medium">Editar Usuario</h1>
      </div>
      <form method="post" onSubmit={handleSubmit((data) => handleOnSubmit(data, setFormDisabled))} className="border p-4 md:p-8 rounded-md self-center w-full bg-gray-50 shadow-md">
        <Input name='fullname' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label="Nombre Completo" errors={errors} value={user?.fullname}/>
        <Input name='email' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label="Email" errors={errors}/>
        <Input name='password' register={register} label="Nuevo Password" errors={errors}/>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">Foto de Perfil (Sólo PNG/JPG/JPEG)</label>
          <FileUpload label="Profile Image(s)" 
                      multiple={false}
                      updateFilesCb={updateUploadedFiles}
                      images={profilephoto.images}
          />
        </div>
        <div className='mb-6'>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" onChange={handleToggle} checked={isChecked ? true : false}/>
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer dark:bg-gray-2 peer-checked:after:translate-x-full peer-checked:after:border-gray-800 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-800"></div>
            <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-500">
              {isChecked ? "Deshabilitar administrador" : "Habilitar Administrador"}
            </span>
          </label>
        </div>
        <Button 
          type='submit' 
          label={'¡Actualizar Usuario!'} 
          icon={<FontAwesomeIcon icon={faPaperPlane}/>}
          isLoading={uploading}
        />
      </form>
    </div>
  )
}

export default EditUser;