import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../../components/common/Footer';
import WaveSeparator from '../../../components/common/WaveSeparator';
import CustomHead from '../../../components/CustomHead';
import { RootState } from '../../../state';
import { getSettings } from '../../../state/action-creators/settings';
import { SettingsState } from '../../../state/actions/settings';
import ContactoForm from './ContactoForm';

const Contacto = () => {
  const [visible, setVisible] = useState(true);
  const {settings} = useSelector((state: RootState) => state.settings) as SettingsState;
  const dispatch = useDispatch();
  useEffect(() => {
    const retrieveSettings = () => dispatch(getSettings() as any);
    retrieveSettings();
  },[dispatch]);
  return (
    <>
    <div className='w-full relative h-screen bg-white flex flex-col justify-between'>
      <CustomHead title='Revuelo Films - Contacto'/>
      <div className="relative wrapper-banner">
        <div className='w-full img-banner bg-grey h-fit py-12 bg-contact' 
          style={{backgroundImage: `url(${settings?.contactbackground})`}}
          >
          <div className="w-full h-fit flex items-center justify-center mb-0">
            <div className=" py-20 px-8 w-auto md:w-full max-w-1xl text-white text-center">
                <h1 className='font-berlin text-3xl md:text-4xl lg:text-5xl header-title'>CONTACTO</h1>
                <p>¡Contacta con nosotros para más información!</p>
              </div>
            </div>
            <WaveSeparator isWhite={true}/>
          </div>
      </div>
      <div className='py-14'>
        <div className='m-5 py-0 md:py-12 lg:px-28 px-0 md:px-8 w-auto flex flex-col lg:flex-row items-center'>
          <ContactoForm/>
        </div>
      </div>
      
      <Footer isFromWebsite={true}/>
    </div>
    </>
  )
}

export default Contacto