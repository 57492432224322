import React from 'react';
import { FieldValues, Path, RegisterOptions } from "react-hook-form";

interface IInput<T extends FieldValues>{
  name: Path<T>;
  registerOptions?: RegisterOptions<T>;
  register: any;
  label: string;
  errors: any;
  value?: string;
  placeholder?: string;
  showRequired?: boolean;
}

const Input = <T extends Record<string, unknown>>({name, registerOptions, register, label, errors, value, placeholder, showRequired = true}: IInput<T>) => {
  // const { handleSubmit, register, watch, setValue } = useForm<T>()
  return (
    <div className="mb-3 w-full">
      <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">
        {label} {(registerOptions?.required && showRequired) && <span className='text-gray-900 dark:text-gray-500'>(Requerido)</span>}
      </label>
      <input type={name === 'password' ? name : 'text'} id={name} className="shadow-sm bg-white border border-border text-gray-900 text-md rounded-lg focus:ring-gray-800 focus:border-gray-800 focus:outline-none block w-full p-2.5" placeholder={placeholder}
      {...register(name, registerOptions)}
      />
      {(errors[name]) ? <label className='text-red-400'>{errors[name]?.message || ''}</label> : ''}
    </div>
  )
}

export default Input