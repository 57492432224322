import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

interface IGoBack{
  url?: string,
  isFloating?: boolean
}
const GoBack = ({url, isFloating = false}: IGoBack) => {
  const navigate = useNavigate();
  return (
    <Link to={url || ""}>
      <button type="button" className={`focus:ring-2 focus:outline-none focus:shadow-md font-medium rounded-full text-sm p-3 text-center inline-flex items-center w-fit transition ease-in-out text-md transition duration-300 ${isFloating ? 'text-white bg-gray-800 hover:bg-gray-900 z-50 m-2' : 'text-gray bg-gray-transparent hover:bg-gray-200 focus:bg-gray-300'}`}>
        <FontAwesomeIcon icon={faChevronLeft} size='lg' className='mr-3'/>
        Volver
      </button>
    </Link>
  )
}

export default GoBack