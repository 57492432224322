import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/common/Loading';
import CustomHead from '../../../components/CustomHead';
import { RootState } from '../../../state';
import { assignFavorite, deleteMessage, getContacts } from '../../../state/action-creators/contact';
import { Contact, ContactState } from '../../../state/actions/contact';
import { parseTimestamp } from '../../../utils/helpers';
import Table, { SelectColumnFilter } from './ContactTable';

const getData = () => [
  {
    "_id": "1",
    "fullname": "John Doe",
    "phonenumber": "555-555-1234",
    "email": "johndoe@example.com",
    "message": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": true,
    "timestamp": "2022-04-08T08:12:37.274Z", // new timestamp property
  },
  {
    "_id": "2",
    "fullname": "Jane Doe",
    "phonenumber": "555-555-5678",
    "email": "janedoe@example.com",
    "message": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": false,
    "timestamp": "2022-04-07T12:34:56.789Z", // new timestamp property
  },
  {
    "_id": "3",
    "fullname": "Bob Smith",
    "phonenumber": "555-555-9012",
    "email": "bobsmith@example.com",
    "message": "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": true,
    "timestamp": "2022-04-07T12:34:56.789Z", // new timestamp property
  },
  {
    "_id": "4",
    "fullname": "Alice Jones",
    "phonenumber": "555-555-3456",
    "email": "alicejones@example.com",
    "message": "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": false,
    "timestamp": "2022-04-07T12:34:56.789Z", // new timestamp property
  },
  {
    "_id": "5",
    "fullname": "Mike Johnson",
    "phonenumber": "555-555-7890",
    "email": "mikejohnson@example.com",
    "message": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": true,
    "timestamp": "2022-04-07T12:34:56.789Z", // new timestamp property
  },
  {
    "_id": "6",
    "fullname": "Sarah Lee",
    "phonenumber": "555-555-2345",
    "email": "sarahlee@example.com",
    "message": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": false,
    "timestamp": "2022-04-07T12:34:56.789Z", // new timestamp property
  },
  {
    "_id": "7",
    "fullname": "David Nguyen",
    "phonenumber": "555-555-6789",
    "email": "davidnguyen@example.com",
    "message": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": true,
    "timestamp": "2022-04-07T12:34:56.789Z", // new timestamp property
  },
  {
    "_id": "8",
    "fullname": "Emily Chen",
    "phonenumber": "555-555-0123",
    "email": "emilychen@example.com",
    "message": "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": false,
    "timestamp": "2022-04-07T12:34:56.789Z", // new timestamp property
  },
  {
    "_id": "9",
    "fullname": "Kevin Kim",
    "phonenumber": "555-555-4567",
    "email": "kevinkim@example.com",
    "message": "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": true,
    "timestamp": "2022-04-07T12:34:56.789Z", // new timestamp property
  },
  {
    "_id": "10",
    "fullname": "Linda Garcia",
    "phonenumber": "555-555-8901",
    "email": "lindagarcia@example.com",
    "message": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "subject": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "favorite": false,
    "timestamp": "2022-04-07T12:34:56.789Z", // new timestamp property
  }
];

const Contacto = () => {
  const [mapFly, setMapFly] = useState(null);
  const columns = React.useMemo(
    () => [
      {
        Header: "Nombres",
        FilterLabel: 'Filtrar por Favorito',
        accessor: "favorite",
        Filter: SelectColumnFilter,
        // show: false,
        filter: (rows: any, id: any, filterValue: any) => {
          return filterValue === ""
            ? rows
            : rows.filter((row: any) => {
                const value = row.values[id];
                if (value === undefined) {
                  return false;
                }
                return value.toString().includes(filterValue.toString());
              });
        },
      },
      {
        Header: "Asunto",
        accessor: "subject",
        Cell: ({ cell: { value } }: any) => <div>{value.length > 60 ? value.slice(0 , 60) + "..." : value}</div>
        // Filter: SelectColumnFilter,
        // filter: "includes"
      },
      {
        Header: "Fecha",
        accessor: "timestamp",
        Cell: ({ cell: { value } }: any) => <div>{parseTimestamp(value)}</div>
        // Filter: SelectColumnFilter,
        // filter: "includes"
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    []
  );
  // const data = prepareData(getData());
  // const data = React.useMemo(() => prepareData(getData()), []);
  const {contacts, loading} = useSelector((state: RootState) => state.contact) as ContactState;
  const [messages, setMessages] = useState<Contact[]>([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm<Contact>();
  const dispatch = useDispatch();
  const handleOnSubmit = (data: any, setFormDisabled: (disabled: boolean) => void) => {

  }
  const handleMessage = () => {

  }
  const toggleFavorite = async (id: string, favorite: boolean) => {
    await dispatch(assignFavorite(id, favorite) as any);
    setMessages((prevData: any) =>
      prevData.map((row: any) => {
        return row._id === id ? { ...row, favorite: !row.favorite } : row
      })
    );
  };

  const handleDelete = async (id: string) => {    
    const confirmed = window.confirm("¿Estás seguro de eliminar este mensaje?")
    if (confirmed) {
      await dispatch(deleteMessage(id) as any);
      setMessages((prevData: any) =>
        prevData.map((row: any) => {
          return row._id === id ? { ...row, favorite: !row.favorite } : row
        })
      );
    }
    return;
  };
  function prepareData(data: any) {
    return data.map((row: any) => {
      return {
        ...row,
        favorite: row.favorite || false,
        timestamp: new Date(row.timestamp),
        toggleFavorite: (_id: string, favorite: boolean) => {
          toggleFavorite(_id, favorite);
        },
        handleDelete: (_id: string) => {
          handleDelete(_id);
        }
      }
    });
  }
  useEffect(() => {
    if(!loading && contacts.length > 0){
      setMessages(prepareData(contacts));
    }
  },[loading, contacts])
  
  useEffect(() => {
    const retrieveContacts = () => dispatch(getContacts() as any);
    retrieveContacts();
  },[dispatch])

  console.log("messages: ",messages);

  return (
    <div className="p-4 md:p-8 flex flex-col lg:w-3/4 sm:w-full w-full m-auto h-screen md:h-auto">
      <CustomHead title="Revuelo - Contacto"/>
      <div className="flex items-center gap-x-5 mb-2 w-full justify-between">
        <h1 className="text-lg md:text-2xl text-gray-800 font-medium">Revuelo - Contacto</h1>
      </div>
        {
          loading ? <Loading /> :
          <Table columns={columns} data={messages}/>
        }
    </div>
  )
}

export default Contacto