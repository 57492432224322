import { SettingsType } from "../action-types/settings";
import { SettingsAction, SettingsState } from "../actions/settings";

const initialState: SettingsState = {
  settings: null,
  loading: true,
  error: null,
}

const reducer = (state: SettingsState = initialState, action: SettingsAction) => {
  switch(action.type){
    case SettingsType.RETRIEVE_ALL_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
        error: null,
      }
    case SettingsType.ADD_EDIT_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
        error: null
      }
    case SettingsType.ERROR_SETTINGS:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state;
  }
}

export default reducer;