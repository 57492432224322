import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect } from 'react';
import { FieldValues, Path, RegisterOptions } from 'react-hook-form';

interface IInput<T extends FieldValues>{
  name: Path<T>;
  registerOptions?: RegisterOptions<T>;
  register: any;
  label: string;
  errors: any;
  value?: string;
  placeholder?: string;
  showRequired?: boolean;
  handleFileChange: (evt: ChangeEvent<HTMLInputElement>) => void,
  selectedDocument: any,
  setSelectedDocument?: any,
  pdfdocument?: File
}

const FileInput = <T extends Record<string, unknown>>({name, registerOptions, register, label, errors, value, handleFileChange, selectedDocument, setSelectedDocument, pdfdocument}: IInput<T>) => {
  useEffect(() => {
    if (pdfdocument){
      setSelectedDocument(pdfdocument);
    }
  },[pdfdocument])
  console.log("selectedDocument: ",selectedDocument);
  return (
    <div className="flex items-start justify-start w-fit my-4 flex-col gap-2">
      <label htmlFor="file-upload" className="relative cursor-pointer bg-gray-800 hover:bg-gray-600 rounded-md font-medium text-white p-2 px-4 transition duration-300">
        <span className="text-center text-sm">
          <FontAwesomeIcon icon={faFilePdf} className='mr-2'/>
          {label}
        </span>
        <input
          id="file-upload"
          name={name}
          type="file"
          className="sr-only"
          accept=".pdf"
          {...register(name, registerOptions)}
          onChange={handleFileChange}
        />
      </label>
      {selectedDocument && (
        <p className="text-sm text-gray-600">{selectedDocument.name}</p>
      )}
      {(errors[name]) ? <label className='text-red-400'>{errors[name]?.message || ''}</label> : ''}
    </div>
  );
};

export default FileInput;