
import React from 'react'

const WaveFooterSeparator = ({isWhite = false}: {isWhite?: boolean}) => {
  return (
    <div className="custom-shape-divider-top-1663265067">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M649.97 0L550.03 0 599.91 54.12 649.97 0z" className={`${isWhite ? 'shape-fill' : 'shape-grey'}`}></path>
        </svg>
    </div>
  )
}

export default WaveFooterSeparator