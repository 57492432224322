import React, { useState } from 'react';
import FileUpload from '../../../components/common/FileUpload';
import { IBackground } from './Configuracion';

interface IBackgroundManager {
  imagesFiles: IBackground | any,
  updateUploadedFiles: (fieldName: string, files: File[]) => void
}

const BackgroundManager = ({updateUploadedFiles, imagesFiles}: IBackgroundManager) => {
  const [activeTab, setActiveTab] = useState('home');
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const buttonsData = [
    { label: 'Home', tab: 'home', rounded: 'tl' },
    { label: 'Nosotros', tab: 'nosotros', rounded: '' },
    { label: 'Proyectos', tab: 'proyectos', rounded: '' },
    { label: 'Indivual', tab: 'individual', rounded: '' },
    { label: 'Contacto', tab: 'contacto', rounded: 'tr' },
  ];
  const tabsData = [
    { tab: 'home', label: 'Image Background', fieldName: 'homebackground' },
    { tab: 'nosotros', label: 'Image Background', fieldName: 'aboutbackground' },
    { tab: 'proyectos', label: 'Image Background', fieldName: 'proyectobackground' },
    { tab: 'individual', label: 'Image Background', fieldName: 'proyectoindividualbackground' },
    { tab: 'contacto', label: 'Image Background', fieldName: 'contactbackground' },
  ];
  console.log("imagesFiles: ",imagesFiles);
  return (
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">
        Fondos de Pantalla
      </label>
      <div className='flex flex-col mb-4 rounded-md'>
        <div className={`grid grid-cols-2 md:grid-cols-5 w-full rounded-md`}>
          {
            buttonsData.map((button) => (
              <button
                key={button.tab}
                type="button"
                className={`${
                  activeTab === button.tab ? 'bg-gray-800 text-white' : 'bg-gray-100 hover:bg-gray-200'
                } focus:outline-none px-4 py-2 rounded-${button.rounded}-md transition duration-300`}
                onClick={() => handleTabChange(button.tab)}
              >
                {button.label}
              </button>
            ))
          }
        </div>
        <div className='p-4 bg-white shadow rounded-b-md'>
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">Imagen (Sólo PNG/JPG/JPEG)</label>
          {
            tabsData.map((tabData: any, idx: number) => (
              activeTab === tabData.tab && (
                <FileUpload
                  key={idx}
                  label={tabData.label}
                  multiple={false}
                  updateFilesCb={(file) => updateUploadedFiles(tabData.fieldName, file)}
                  maxFileSizeInBytes={5000000}
                  images={imagesFiles[tabData.fieldName] ? [imagesFiles[tabData.fieldName]] : []}
                />
              )
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default BackgroundManager