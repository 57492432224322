import React from 'react';
import ReactLoading from 'react-loading';

interface ILoading {
  isButton?: boolean
}
const Loading = ({isButton = false}: ILoading) => {
  return (
    <>
      {
        (!isButton) ? 
          <div className={`w-full flex justify-center items-center my-10`}>
            <ReactLoading type='spin' color={'#111827'} height={'5%'} width={'8%'}/>
          </div>:
          <ReactLoading type='spin' color={'#fff'} height={'100%'} width={'15px'} className='mr-2'/>
      }
    </>
  )
}

export default Loading