import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { url } from "../../utils/constants";
import { SettingsType } from "../action-types/settings";
import { Action } from "../actions";
import { Settings, SettingsAction } from "../actions/settings";
import { setAlert } from "./alert";

interface SettingsResponse {
  ok: boolean,
  data: Settings
}
interface SingleSettingsResponse {
  ok: boolean,
  data: Settings
}
interface DataSingleSettings{
  data: SingleSettingsResponse
}
interface Data{
  data: SettingsResponse
}

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
}
export const getSettings = () => async(dispatch: Dispatch<SettingsAction | Action | any>) => {
  dispatch({
    type: SettingsType.CLEAR_SETTINGS,
  })
  try {
    const {data: response}: Data  = await axios.get(`${url}/settings`, config);
    console.log("response: ",response)
    dispatch({
      type: SettingsType.RETRIEVE_ALL_SETTINGS,
      payload: response.data
    })
  } catch (err) {
    let error = err as AxiosError;
    if(error.response) {
      dispatch({
        type: SettingsType.ERROR_SETTINGS,
        payload: {
          msg: error.response.data as string,
          status: error.response.status
        }
      })
    }
  }
}

export const addSettings = (formData: Settings) => async(dispatch: Dispatch<SettingsAction | Action | any>) => {
  try {
    const {data: response}: Data = await axios.post(`${url}/settings`,formData ,config);
    console.log("res: ",response);
    dispatch({
      type: SettingsType.ADD_EDIT_SETTINGS,
      payload: response.data
    })
    dispatch(setAlert("Cambios guardados satisfactoriamente", "success"));
  } catch (err) {
    let error = err as AxiosError | any;
    const errors = error?.response?.data.errors;
    if(errors){
      errors.forEach((error: any) => dispatch(setAlert(error.msg,'error') as any));
    }
    if(error.response) {
      dispatch({
        type: SettingsType.ERROR_SETTINGS,
        payload: {
          msg: error.response.data,
          status: error.response.status
        }
      })
    }
  }
}