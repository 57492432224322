import { faAngleDoubleLeft, faAngleDoubleRight, faChevronLeft, faChevronRight, faCrown, faEdit, faSort, faSortDown, faSortUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useAsyncDebounce,
  useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable
} from "react-table";
import MarkerButton from "../../../components/common/MarkerButton";
import { Button, PageButton } from "../../../components/common/TableButtons";
import { classNames, getGravatarUrl } from "../../../utils/helpers";
import { MapProvider } from "../../../utils/MapProvider";
// import MarkerButton from "./MarkerButton";
// import StarEmpty from "./StarEmpty";
// import StarFilled from "./StarFilled";
// import { Button, PageButton } from "./TableButtons";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}: any) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">Search: </span>
      <input
        type="text"
        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render }
}: any) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    return [
      { label: "Todos", value: "" },
      { label: "Favorito", value: true },
      { label: "No favorito", value: false }
    ];
  }, []);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("FilterLabel")}: </span>
      <select
        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        {/* <option value="">Todos</option> */}
        {options.map((option, i) => (
          <option key={i} value={option.value.toString()}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
}

export function StatusPill({ value }: any) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("active") ? "bg-green-100 text-green-700" : null,
        status.startsWith("inactive") ? "bg-yellow-100 text-yellow-700" : null,
        status.startsWith("offline") ? "bg-red-100 text-red-700" : null
      )}
    >
      {status}
    </span>
  );
}

export function LocateCell({ value, column, row }: any) {
  // function handleClick() {
  //   //console.log(flying);
  //   row.original[column.mapAccessor].flyTo(row.original[column.posAccessor]);
  // }

  return (
    <>
      <MapProvider>
        <MarkerButton pos={row.original[column.posAccessor]} />
      </MapProvider>
    </>
  );
}

// Define a checkbox component that works with indeterminate state
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function Table({ columns, data, onClick }: any) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  }: any = useTable(
    {
      columns,
      data
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        ...columns
      ]);
    }
  );
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState(false);


  // Render the UI for your table
  return (
    <>
      {headerGroups.map((headerGroup: any) =>
        headerGroup.headers.map((column: any) =>
          column.Filter ? (
            <div key={column.id}>{column.render("Filter")}</div>
          ) : null
        )
      )}
      <div className="mt-2 flex flex-col">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg my-3">
          <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 w-full">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200 w-full"
              >
                <thead className="bg-gray-800">
                  {headerGroups.map((headerGroup: any) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                          {...column.getHeaderProps(
                            column.id === "actions" ? {} : column.getSortByToggleProps()
                          )}
                        >
                          <div className="flex items-center justify-between">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                            {column.id === "actions" ? null :
                              (column.isSorted ? (
                                column.isSortedDesc ? (
                                  <FontAwesomeIcon icon={faSortDown}/>
                                ) : (
                                  <FontAwesomeIcon icon={faSortUp}/>
                                )
                              ) : (
                                <FontAwesomeIcon icon={faSort}/>
                              ))}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page.map((row: any, i: number) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} 
                        className=''>
                        {row.cells.map((cell: any, j: number) => {
                          // if (cell.column.id === "fullname") return;
                          if (cell.column.id === "profilephoto" || 
                              cell.column.id === "fullname" || 
                              cell.column.id === "isadmin") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-2 whitespace-nowrap text-center"
                              >
                                <div className="flex items-center gap-2">
                                  <img src={row.original.profilephoto || getGravatarUrl(row.original.email)} alt="profile photo" className='w-12 h-12 rounded-full object-fit'/>
                                  <span>{row.original.fullname}</span>
                                  {row.original.isadmin && <FontAwesomeIcon icon={faCrown} size='lg' className='text-yellow-400 self-justify-center'/>}
                                </div>
                              </td>
                            );
                          } else if (cell.column.id === 'actions'){
                            return (
                              <td {...cell.getCellProps()} className='text-center'>
                                <Link to={`/admin/users/edit/${row.original._id}`} className="px-0 py-0 whitespace-nowrap text-center text-yellow-500 mr-4 cursor-pointer">
                                  <FontAwesomeIcon icon={faEdit} size='lg'/>
                                </Link>
                                <a href="#" className="px-0 py-0 whitespace-nowrap text-center text-red-500 cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  row.original.handleDelete(row.original._id)
                                }}>
                                  <FontAwesomeIcon icon={faTrash} size='lg'/>
                                </a>
                              </td>
                            )
                          } else {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-4 whitespace-nowrap"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Anterior
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Siguiente
          </Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex gap-x-2 items-center">
            <span className="text-sm text-gray-700">
              Página <span className="font-medium">{state.pageIndex + 1}</span> de{" "}
              <span className="font-medium">{pageOptions.length}</span>
            </span>
            <label>
              <span className="sr-only">Mensajes por pagina</span>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <FontAwesomeIcon icon={faAngleDoubleLeft}/>
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <FontAwesomeIcon icon={faChevronLeft}/>
              </PageButton>
              <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                <span className="sr-only">Next</span>
                <FontAwesomeIcon icon={faChevronRight}/>
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <FontAwesomeIcon icon={faAngleDoubleRight}/>
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
