import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { url } from "../../utils/constants";
import { AboutType } from "../action-types/about";
import { Action } from "../actions";
import { About, AboutAction } from "../actions/about";
import { setAlert } from "./alert";

interface AboutResponse {
  ok: boolean,
  data: About
}
interface SingleAboutResponse {
  ok: boolean,
  data: About
}
interface DataSingleAbout{
  data: SingleAboutResponse
}
interface Data{
  data: AboutResponse
}

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
}
export const getAbout = () => async(dispatch: Dispatch<AboutAction | Action>) => {
  dispatch({
    type: AboutType.CLEAR_ABOUT,
  })
  try {
    const {data: response}: Data  = await axios.get(`${url}/about`, config);
    console.log("response: ",response)
    dispatch({
      type: AboutType.RETRIEVE_ALL_ABOUT,
      payload: response.data
    })
  } catch (err) {
    let error = err as AxiosError;
    if(error.response) {
      dispatch({
        type: AboutType.ERROR_ABOUT,
        payload: {
          msg: error.response.data as string,
          status: error.response.status
        }
      })
    }
  }
}

export const addAbout = (formData: About) => async(dispatch: Dispatch<AboutAction | Action | any>) => {
  try {
    const {data: response}: Data = await axios.post(`${url}/about`,formData ,config);
    console.log("res: ",response);
    dispatch({
      type: AboutType.ADD_EDIT_ABOUT,
      payload: response.data
    })
    dispatch(setAlert("Cambios guardados satisfactoriamente", "success"));
  } catch (err) {
    let error = err as AxiosError | any;
    const errors = error?.response?.data.errors;
    if(errors){
      errors.forEach((error: any) => dispatch(setAlert(error.msg,'error') as any));
    }
    if(error.response) {
      dispatch({
        type: AboutType.ERROR_ABOUT,
        payload: {
          msg: error.response.data,
          status: error.response.status
        }
      })
    }
  }
}