import { PostType } from "../action-types/post";
import { PostAction, PostState } from "../actions/post";

const initialState: PostState = {
  posts: [],
  post: null,
  loading: true,
  error: null,
}

const reducer = (state: PostState = initialState, action: PostAction) => {
  switch(action.type){
    case PostType.RETRIEVE_ALL_POSTS:
      return {
        ...state,
        posts: action.payload,
        loading: false,
        error: null,
      }
    case PostType.RETRIEVE_SINGLE_POST:
      return {
        ...state,
        post: action.payload,
        loading:false,
        error:null
      }
    case PostType.ADD_POST:
      return {
        ...state,
        posts: [action.payload, ...state.posts],
        loading: false,
        error: null
      }
    case PostType.EDIT_POST:
    case PostType.ENABLE_POST:
      return {
        ...state,
        loading: false,
        posts: state.posts.map(post => {
          if (post._id === action.payload._id) {
            return action.payload; 
          } else {
            return post;
          }
        })
      }
    case PostType.DELETE_POST:
      return {
        ...state,
        loading: false,
        posts: state.posts.filter(post => post._id !== action.payload)
      }
    case PostType.ERROR_POST:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case PostType.CLEAR_POSTS:
    return {
        ...state,
        posts: [],
        loading: true,
      }
    default:
      return state;
  }
}

export default reducer;