import { faCheckCircle, faClose, faExclamationCircle, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state";
// import { useToast, UseToastOptions } from "@chakra-ui/react";
import { removeAlert } from "../state/action-creators/alert";
import { Alert, AlertState } from "../state/actions/alert";

interface CustomAlertProps {
  status: 'success' | 'error' | 'warning' | 'info';
  message: string;
}

function Alerts() {
  const dispatch = useDispatch();
  const {alerts}: AlertState = useSelector((state: RootState) => state.alerts);
  
  useEffect(() => {
    alerts !== null &&
      alerts.length > 0 &&
      alerts.map((alert: Alert) => {
        setTimeout(() => {
          dispatch(removeAlert(alert.id));
        }, 5000);
      });
  }, [dispatch, alerts]);

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 w-full md:w-fit p-5">
      {alerts !== null &&
        alerts.length > 0 &&
        alerts.map((alert: Alert) => {
          let color = '';
          let icon = null;
          switch(alert.alertType) {
            case 'info':
              color = 'bg-blue-100 text-blue-800';
              icon = <FontAwesomeIcon icon={faInfoCircle} size='lg'/>;
              break;
            case 'warning':
              color = 'bg-yellow-100 text-yellow-800';
              icon = <FontAwesomeIcon icon={faExclamationTriangle} size='lg'/>;
              break;
            case 'success':
              color = 'bg-green-100 text-green-800';
              icon = <FontAwesomeIcon icon={faCheckCircle} size='lg'/>;
              break;
            case 'error':
              color = 'bg-red-100 text-red-800';
              icon = <FontAwesomeIcon icon={faExclamationCircle} size='lg'/>;
              break;
            default:
              color = 'bg-gray-100 text-gray-800';
              icon = <FontAwesomeIcon icon={faInfoCircle} size='lg'/>;
              break;
          }
          return (
            <div
              key={alert.id}
              className={`${color} px-4 py-3 rounded-lg shadow-md mb-4 flex items-center justify-center w-full md:w-fit`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className={`w-6 h-6 mr-2 ${alert.alertType === 'error' ? 'text-red-800' : 'text-green-800'}`}>
                    {icon}
                  </span>
                  <span className="font-semibold">{alert.msg}</span>
                </div>
                <button
                  className="ml-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                  onClick={() => dispatch(removeAlert(alert.id))}
                >
                  <FontAwesomeIcon icon={faClose} size='lg'/>
                </button>
              </div>
            </div>
          );
        })
        }
    </div>
  );
}

export default Alerts;