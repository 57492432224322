import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../components/common/Footer'
import Loading from '../../components/common/Loading'
import WaveSeparator from '../../components/common/WaveSeparator'
import CustomHead from '../../components/CustomHead'
import { RootState } from '../../state'
import { getAbout } from '../../state/action-creators/about'
import { getSettings } from '../../state/action-creators/settings'
import { AboutState } from '../../state/actions/about'
import { SettingsState } from '../../state/actions/settings'

const Nosotros = () => {
  const {about, loading} = useSelector((state: RootState) => state.about) as AboutState;
  const {settings} = useSelector((state: RootState) => state.settings) as SettingsState;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAbout() as any);
  },[dispatch])
  useEffect(() => {
    const retrieveSettings = () => dispatch(getSettings() as any);
    retrieveSettings();
  },[dispatch]);
  // console.log("about: ",about);
  return (
    <div className='w-full h-screen bg-grey'>
      <CustomHead title='Revuelo Films - Nosotros'/>
      <div className="relative wrapper-banner">
        <div className='w-full img-banner bg-grey h-fit py-12 bg-about' 
          style={{backgroundImage: `url(${settings?.aboutbackground})`}}
        >
          <div className="w-full h-fit flex items-center justify-center mb-0">
            <div className=" py-20 px-8 w-auto md:w-full max-w-1xl text-white text-center">
              <h1 className='font-berlin text-3xl md:text-4xl lg:text-5xl header-title'>NOSOTROS</h1>
            </div>
          </div>
          <WaveSeparator/>
        </div>
      </div>
      <div className='py-14 bg-grey'>
        <div className='m-5 py-0 md:py-12 lg:px-28 px-0 md:px-8 w-auto flex flex-col lg:flex-row md:items-center lg:items-start gap-5'>
          {
            loading ? <Loading/> :
            <div className='grid md:grid-cols-2 gap-4 items-start'>
              <img src={about?.image[0] || "/logos/revuelo-main-logo-black.png"} alt="" className='flex object-contain' />
              <div className='w-full lg:p-0 lg:py-0'>
                <h3 className='text-2xl text-gray-800 text-bold font-nova'>{about?.title}</h3>
                <p className='text-lg text-gray-500 font-nova'>{about?.body}</p>
              </div>
            </div>
          }
        </div>
      </div>
      <Footer isFromWebsite={true} isWhite={false}/>
    </div>
  )
}

export default Nosotros