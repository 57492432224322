import { UserType } from "../action-types/users";
import { UserAction, UserState } from "../actions/user";

const initialState: UserState = {
  users: [],
  user: null,
  loading: true,
  error: null,
}

const reducer = (state: UserState = initialState, action: UserAction) => {
  switch(action.type){
    case UserType.RETRIEVE_ALL_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      }
    case UserType.RETRIEVE_SINGLE_USER:
      return {
        ...state,
        user: action.payload,
        loading:false,
        error:null
      }
    case UserType.ADD_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
        loading: false,
        error: null
      }
    case UserType.EDIT_USER:
      return {
        ...state,
        loading: false,
        users: state.users.map(user => {
          if (user._id === action.payload._id) {
            return action.payload; 
          } else {
            return user;
          }
        })
      }
    case UserType.DELETE_USER:
      return {
        ...state,
        loading: false,
        users: state.users.filter(user => user._id !== action.payload)
      }
    case UserType.ERROR_USER:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UserType.CLEAR_USERS:
    return {
        ...state,
        users: [],
        loading: true,
      }
    default:
      return state;
  }
}

export default reducer;