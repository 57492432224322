import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { url } from "../../utils/constants";
import setAuthToken from "../../utils/setAuthToken";
import { AuthType } from "../action-types/auth";
import { Action } from "../actions";
import { AuthAction } from "../actions/auth";
import { setAlert } from "./alert";


const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export const loadUser = () => async(dispatch: Dispatch<AuthAction>) => {
  try {
    if(localStorage.token){
      setAuthToken(localStorage.token);
    }
    // console.log("localStorage.token: ",localStorage.token);
    //Getting user info -> email
    const {data: {data: responseAuth}} = await axios.get(`${url}/auth`, config);
    dispatch({
      type: AuthType.USER_LOADED,
      payload: responseAuth
    })
  } catch (err) {
    dispatch({
      type: AuthType.AUTH_ERROR,
    })
  }
}

export const auth = (email: string, password: string) => async(dispatch: Dispatch<AuthAction | Action>) => {
  const body = JSON.stringify({email,password});
  try {
    //Getting token from backend
    const {data: {data: token}} = await axios.post(`${url}/auth`,body,config);
    // console.log("responseAuth: ", token)
    dispatch({
      type: AuthType.LOGIN_SUCCESS,
      payload: token
    })
    setAuthToken(token);
    const mydispatch = useDispatch();
    mydispatch(loadUser() as any);
  } catch (err) {
    let error = err as AxiosError | any;
    if(error instanceof AxiosError) {
      const errors = error?.response?.data.errors;
      if(errors){
        errors.forEach((error: any) => dispatch(setAlert(error.msg,'error') as any));
      }
      dispatch({
        type: AuthType.LOGIN_FAIL,
      })
    }
  }
};

export const logout = () => async(dispatch: Dispatch<AuthAction | Action>) => {
  dispatch({type: AuthType.LOGOUT});
}
// export const logout = (): AuthAction => ({
//   type: AuthType.LOGOUT
// });