import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import {
  FileMetaData, FilePreviewContainer, FormField, PreviewContainer, PreviewImage, PreviewList, RemoveFileIcon
} from "../styles/file-upload-styles";
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1000000;
const KILO_BYTES_PER_BYTE = 1000;
const fileTypes = ["JPEG", "PNG", "GIF"];

const faTrashIcon = faTrash as IconProp
const convertBytesToKB = (bytes: number) => Math.round(bytes / KILO_BYTES_PER_BYTE);
const FileUpload = ({
    key,
    label,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    images,
    multiple,
    ...otherProps
  }:{
    key?: any,
    label: string,
    updateFilesCb: (args: any) => void,
    maxFileSizeInBytes?: number
    multiple: boolean,
    images?: Array<File>
  }) => {
  const [files, setFiles] = useState<any>({});
  const fileInputField = useRef<any>(null);
  const [uploading, setUploading] = useState(false);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };
  const handleNewFileUpload = (e: any) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };
  const convertNestedObjectToArray = (nestedObj: any) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

  const callUpdateFilesCb = (files: any) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };
  const addNewFiles = (newFiles: any) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!multiple) {
          return { file };
        }
        files[file.name] = file;
      }else{
        alert(`Imagen excede ${maxFileSizeInBytes / 1000000}mb, ingresa una imagen menor a ${maxFileSizeInBytes / 1000000}mb.`);
      }
    }
    return { ...files };
  };
  const removeFile = (fileName: any) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };
  useEffect(() => {
    if (images){
      setFiles(images);
    }
  },[images])
  // console.log("files: ",files)
  return (
    <>
      <label className='relative w-full flex justify-center w-full h-32 px-4 transition bg-white border-2 border border-border border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
        <span className="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <span className="font-medium text-gray-600">
                Suelta o arrastra las imágenes
            </span>
        </span>
        <FormField
          type="file"
          ref={fileInputField}
          title=""
          onChange={handleNewFileUpload}
          value=""
          className='form-field hidden'
          accept={'.jpg,.png,.jpeg'}
          multiple={multiple || false}
          {...otherProps}
        />
      </label>
      
      <FilePreviewContainer>
        { (files.length > 0) && <label className="block text-sm font-medium text-gray-900 dark:text-gray-500 my-2">Imágenes Cargadas</label> }
        <PreviewList>
          {Object.keys(files).map((fileName:string, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image"
            return multiple ? (
              <PreviewContainer key={fileName}>
                <div>
                  {isImageFile && (
                    <PreviewImage
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData>
                    {/* <span className='break-all md:break-keep'>{file.name}</span> */}
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <RemoveFileIcon onClick={() => removeFile(fileName)} icon={faTrashIcon}/>
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            ) : <div className='bg-blackbackground w-full flex justify-center rounded-md'>
              <img src={URL.createObjectURL(file)} alt="file preview" className='w-auto min-h-400 h-32 object-contain'/>
            </div>;
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  )
}

export default FileUpload