import { faFacebook, faInstagram, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state';
import { getSettings } from '../../state/action-creators/settings';
import { SettingsState } from '../../state/actions/settings';
import WaveFooterSeparator from './WaveFooterSeparator';
const Footer = ({isFromWebsite = true, isWhite = true}: {isFromWebsite?: boolean, isWhite?:boolean}) => {
  const {settings, loading: loadingUser} = useSelector((state: RootState) => state.settings) as SettingsState;
  const dispatch = useDispatch();
  console.log("contact: ",settings);

  useEffect(() => {
    dispatch(getSettings() as any);
  },[dispatch])

  return (
    <section className={`relative bg-gray-800 text-white`}>
      <WaveFooterSeparator isWhite={isWhite}/>
      <div className="w-full">
          <div className="max-w-screen-xl px-4 py-12 mx-auto overflow-hidden sm:px-6 lg:px-8 flex justify-center grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 items-center">
              <div className='w-full flex items-center justify-center h-auto'>
                {
                  isFromWebsite ? 
                  <img src={'/logos/revuelo-hexagon-logo.png'} alt="Revuelo Logo" className='w-full md:w-full ' /> :
                  <img src={'/logos/revuelo-hexagon-logo.png'} alt="Revuelo Logo" className='w-20 md:w-24 ' />
                }
              </div>
              <div className='max-w-lg flex flex-col justify-center items-start font-nova'>
                <h1 className='text-slate-100 mb-4'>CONTACTO</h1>
                {
                  settings && settings?.mail &&
                  <p className='text-slate-500'>Email: <a href={`mailto:${settings.mail}`} className='text-slate-100' target={"_blank"}>{settings.mail}</a></p>
                }
                {/* <p className='text-slate-500'>Telefono: +51 924230225</p>
                <p className='text-slate-500'>Direccion: Lorem ipsum</p> */}
                <p className='text-slate-500'>Website: <a href="https://revueloarts.com" target={"_blank"} className='text-slate-100'>revueloarts.com</a></p>
              </div>
              <div className='flex m-0 col-span-2 md:col-span-1 justify-center h-fit mt-8 md:m-auto'>
                {
                  settings && settings?.facebook &&
                  <a href={settings.facebook} className="text-gray-400 hover:text-gray-500 px-2 hover:-translate-y-1 hover:ease-in-out hover:duration-300" target={"_blank"}>
                      <span className="sr-only">{settings.facebook}</span>
                      <FontAwesomeIcon icon={faFacebook} size='lg'/>
                  </a>
                }
                {
                  settings && settings?.instagram &&
                  <a href={settings.instagram} className="text-gray-400 hover:text-gray-500 px-2 hover:-translate-y-1 hover:ease-in-out hover:duration-300" target={"_blank"}>
                      <span className="sr-only">Instagram</span>
                      <FontAwesomeIcon icon={faInstagram} size='lg'/>
                  </a>
                }
                {
                  settings && settings?.twitter &&
                  <a href={settings.twitter} className="text-gray-400 hover:text-gray-500 px-2 hover:-translate-y-1 hover:ease-in-out hover:duration-300" target={"_blank"}>
                      <span className="sr-only">Twitter</span>
                      <FontAwesomeIcon icon={faTwitter} size='lg'/>
                  </a>
                }
                {
                  settings && settings?.tiktok &&
                  <a href={settings.tiktok} className="text-gray-400 hover:text-gray-500 px-2 hover:-translate-y-1 hover:ease-in-out hover:duration-300" target={"_blank"}>
                      <span className="sr-only">Tiktok</span>
                      <FontAwesomeIcon icon={faTiktok} size='lg'/>
                  </a>
                }
                {
                  settings && settings?.youtube &&
                  <a href={settings.youtube} className="text-gray-400 hover:text-gray-500 px-2 hover:-translate-y-1 hover:ease-in-out hover:duration-300" target={"_blank"}>
                      <span className="sr-only">Youtube</span>
                      <FontAwesomeIcon icon={faYoutube} size='lg'/>
                  </a>
                }
              </div> 
          </div>
          <div className='bg-zinc-900 py-5'>
            <p className="text-base leading-6 text-center text-gray-400 text-sm font-nova">
              © Revuelo Arts {new Date().getFullYear()} 
            </p>
          </div>
      </div>
  </section>
  )
}

export default Footer