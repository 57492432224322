// import FileUpload from "../../../components/admin/FileUpload";
// import CustomHead from "../../../components/CustomHead";
// import Editor from "../../../components/EditorText";
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { ChangeEvent, useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import Button from '../../../components/common/Button';
import FileInput from '../../../components/common/FileInput';
import FileUpload from "../../../components/common/FileUpload";
import GoBack from "../../../components/common/GoBack";
import Input from '../../../components/common/Input';
import Loading from '../../../components/common/Loading';
import SocialMediaInput from '../../../components/common/SocialMediaInput';
import TextArea from '../../../components/common/TextArea';
import CustomHead from "../../../components/CustomHead";
import { RootState } from '../../../state';
import { loadUser } from '../../../state/action-creators/auth';
import { getSinglePost, updatePost } from '../../../state/action-creators/post';
import { AuthState } from '../../../state/actions/auth';
import { Post, PostState } from "../../../state/actions/post";
import { url } from '../../../utils/constants';
import { validateYoutubeUrl } from '../../../utils/helpers';

function EditPost(){
  const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm<Post>();
  register('description', {required: 'Este campo es obligatorio'})
  const {user, loading: loadingUser} = useSelector((state: RootState) => state.auth) as AuthState;
  const [editorState, setEditorState] = useState<EditorState>(() => EditorState.createEmpty());
  const [editorContentState, setEditorContentState] = useState(() =>
    ContentState.createFromText('')
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  // const [content, setContent] = useState<string>('');
  const [content, setContent] = useState<string>(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  const router = useNavigate();
  const [dataToSend, setDataToSend]  = useState<Post>()
  const [ imageSrc, setImageSrc ] = useState<Array<File>>([]);
  const [ uploadData, setUploadData ] = useState<any>();
  const [ imagesUrl, setImagesUrl ] = useState<Array<string>>([]);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [postInfo, setPostInfo] = useState<{images: File[]}>({
    images: []
  });
  const [pdfdocument, setPdfDocument] = useState<File | null>(null);
  const [videoUrls, setVideoUrls] = useState<Array<string>>([]);
  const [isYoutubeUrlValid, setIsYoutubeUrlValid] = useState<boolean[]>([]);
  const [isTouched, setIsTouched] = useState<boolean[]>([]);
  const {id} = useParams();
  const dispatch = useDispatch();
  const [currentPost, setCurrentPost] = useState<Post>()
  const {post, loading} = useSelector((state: RootState) => state.post) as PostState;
  const [selectedDocument, setSelectedDocument] = useState<File | null>(null);

  const handleOnChange = (e: React.FormEvent<HTMLFormElement>) => {
    const reader = new FileReader();
    reader.onload = function(loadEvt){
      if (loadEvt.target){
        setImageSrc((prev: any) => [...prev, loadEvt.target?.result]); 
        setUploadData(null);
        reader.readAsDataURL(e.currentTarget.files[0]);
      }
    }
  }
  
  async function handleOnSubmit(data: Post, setFormDisabled: (disabled: boolean) => void){
    try {
      setFormDisabled(true); // Disable the form
      await uploadFileHandler();
      let documentLoaded;
      if(selectedDocument){
        documentLoaded = await uploadDocumentPDF();
      }
      if(!user?._id || isYoutubeUrlValid.some(url => url === false)) return;
      console.log("formState: ",data);
      let data_to_send = {
        title: data.title,
        previewDesc: data.previewDesc,
        description: data.description.replaceAll('<p></p>', '<br>'),
        images: Object?.values(watch('images') || []),
        pdfdocument: documentLoaded || "",
        user: user._id,
        videos: videoUrls.filter(str => str !== ""),
        enabled: isChecked
      }
      console.log("data.description: ",data_to_send.description);
      // return;
      dispatch(updatePost(id!, data_to_send) as any);
      // return;
      navigate('/admin/posts');
    }catch(error){
      console.log("Error: ",error);
    } finally {
      setFormDisabled(false); // Re-enable the form
    }
  }
  const updateUploadedFiles = (files: Array<File>) => setPostInfo({ ...postInfo, images: files });
  const updateUploadedPDF = (file: File) => setPdfDocument(file);
  
  const uploadFileHandler = async () => {    
    const files = [...postInfo.images];
    if (files.length > 0) {
      const formData = new FormData();
      files.forEach(function(file){
        formData.append("image", file);
      })
      setUploading(true);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post(`${url}/upload`, formData, config);
        // setValue("images", data, { shouldValidate: true, shouldDirty: true });
        console.log("loaded-image: ",data);
        setValue('images',{...data})
        // setImage(data);
        setUploading(false);
      } catch (error) {
        setUploading(false);
      }
    }
  };
  const handleToggle = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const addInputField = () => {
    setVideoUrls([...videoUrls, ""]);
  }

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newUrls = [...videoUrls];
    newUrls[index] = event.target.value;
    setVideoUrls(newUrls);

    const newValidityFlags = [...isYoutubeUrlValid];
    newValidityFlags[index] = validateYoutubeUrl(newUrls[index]);
    setIsYoutubeUrlValid(newValidityFlags);

    const newTouchedFlags = [...isTouched];
    newTouchedFlags[index] = true;
    setIsTouched(newTouchedFlags);
  }
  const removeInputField = (index: number) => {
    const newUrls = [...videoUrls];
    newUrls.splice(index, 1);
    setVideoUrls(newUrls);
  }
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    console.log("file: ",file);
    setSelectedDocument(file || null);
  };
  const uploadDocumentPDF = async () => {
    try {
      const formData = new FormData();
      if (selectedDocument) {
        formData.append('pdfdocument', selectedDocument);
      }
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post(`${url}/upload/document`, formData, config);
      console.log('loaded-document:', data);
      return data;
    } catch (error) {
      console.error('Error uploading files:', error);
      throw error;
    }
  }
  useEffect(() => {
    if(id){
      const retrievePost = () => dispatch(getSinglePost(id) as any);
      retrievePost();
    }
  },[id])
  useEffect(() => {
    if (!loading){
      if(post && post.title.length > 0){
        setValue('title', post.title);
      }
      if(post && post.previewDesc.length > 0){
        // console.log("value-set: ",post.previewDesc);
        setValue('previewDesc', post.previewDesc);
      }
      if(post && post.description && post.description.length > 0){
        const blocksFromHtml = convertFromHTML(post.description);
        const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks);
        // const rawContentState = convertToRaw(contentState);
        setEditorState(EditorState.createWithContent(contentState));

        const contentHtml = draftToHtml(convertToRaw(contentState));
        const contentWithSpecialString = contentHtml.replaceAll('<br>', '__BR_TAG__');

        setContent(contentHtml);
        setValue('description', contentWithSpecialString);
      }
      if(post && post?.pdfdocument){
        setValue('pdfdocument', post.pdfdocument);
      }
      if(post && post.videos && post.videos.length > 0){
        setVideoUrls(post.videos)
      }
      if(post && post.enabled){
        setIsChecked(post.enabled);
      }
    }
  },[post, loading])
  
  useEffect(() => {
    const contentHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setContent(contentHtml);
    setValue('description', content.replaceAll("<br>", "<p>&nbsp;</p>"));
    console.log("content: ",content);
  }, []);

  useEffect(() => {
    if(post && post.images && post.images.length > 0){
      let images = post.images.map(async function(image: string){
        const response = await fetch(`${image}`);
        var blob = await response.blob();
        return new File([blob], image, { type: blob.type });
      })
      Promise.all(images).then((res) => updateUploadedFiles(res));
    }
    if(post && post?.pdfdocument && post.pdfdocument.length > 0){
      let document = [post.pdfdocument].map(async function(image: string){
        const response = await fetch(`${image}`);
        var blob = await response.blob();
        return new File([blob], image, { type: blob.type });
      })
      Promise.all(document).then((res) => updateUploadedPDF(res[0]));
    }
  }, [post])
  useEffect(() => {
    dispatch(loadUser() as any);
  },[dispatch])
  // console.log("value-description: ",content);
  return (
    loading ? <Loading/> :
    <div className="p-4 md:p-8 flex flex-col lg:w-3/4 sm:w-full w-full m-auto">
      <CustomHead title='Revuelo Admin - Crear Proyecto'/>
      <div className="flex items-center gap-x-5 mb-2 w-full justify-between">
        <GoBack url={'/admin/posts'}/>
        <h1 className="text-lg md:text-2xl text-gray-800 font-medium">Actualizar Proyecto</h1>
      </div>
      <form method="post" onSubmit={handleSubmit((data) => handleOnSubmit(data, setFormDisabled))} className="border p-4 md:p-8 rounded-md self-center w-full bg-gray-50 shadow-md" aria-disabled={formDisabled}>
        <Input name='title' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label="Titulo" errors={errors} value={post?.title}/>
        <TextArea name='previewDesc' value={post?.previewDesc} register={register} registerOptions={{required: 'Este campo es obligatorio', maxLength: {
          value: 200,
          message: 'Máximo 200 Caracteres'
        }}} label="Vista Previa de Descripcion" errors={errors}/>
        <div className="mb-6">
          <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">Contenido <span className='text-gray-900 dark:text-gray-500'>(Requerido)</span></label>
          <Editor
              editorState={editorState}
              wrapperClassName="card"
              editorClassName="card-body"
              editorStyle={{lineHeight: '1.25px'}}
              onEditorStateChange={(newState: any) => {
                  setEditorState(newState)
                  setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
                  setValue('description', content);
                }}        
              contentState={convertToRaw(editorState.getCurrentContent())}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
          />
          {(errors['description']) ? <label className='text-red-400'>{errors['description']?.message || ''}</label> : ''}
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">Imágenes (Solo PNG/JPG/JPEG)</label>
          <FileUpload label="Profile Image(s)" 
                      multiple={true}
                      updateFilesCb={updateUploadedFiles}
                      images={postInfo.images}
          />
        </div>
        <div className="mb-6 flex w-full flex-col gap-y-1 border border-border p-4 rounded-md bg-gray-100">
          {/* <RedirectTo text={'Agregar Video'} icon={<FontAwesomeIcon icon={faPlus}/>} onClick={addInputField}/> */}
          <a href="#!" className='text-blue-600 flex items-center w-fit hover:text-blue-700 hover:underline' onClick={addInputField}>
          <FontAwesomeIcon icon={faPlus} className='mr-2'/>
          Agregar Video
          </a>
          <div className='flex flex-col gap-y-2'>
            {videoUrls.map((url, index) => (
              <SocialMediaInput 
                icon={<FontAwesomeIcon icon={faYoutube} size='lg' color='#FE0100'/>} 
                placeholder="Enter video URL" 
                index={index} 
                onChange={handleInputChange} 
                isYoutubeUrlValid={isYoutubeUrlValid[index]} 
                isTouched={isTouched[index]}
                removeInputField={removeInputField} 
                value={url}
                key={index}
              />
            ))}
          </div>
        </div>
        <FileInput 
          name='pdfdocument' 
          register={register} 
          label={"Cargar programa de mano"} 
          errors={errors} 
          handleFileChange={handleFileChange} 
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          pdfdocument={pdfdocument!}/>
        <div className='mb-6'>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" onChange={handleToggle} checked={isChecked ? true : false}/>
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer dark:bg-gray-2 peer-checked:after:translate-x-full peer-checked:after:border-gray-800 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-800"></div>
            <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-500">
              {isChecked ? "Deshabilitar Proyecto" : "Habilitar Proyecto"}
            </span>
          </label>
        </div>
        <Button 
          type='submit' 
          label={'¡Actualizar Proyecto!'} 
          icon={<FontAwesomeIcon icon={faPaperPlane}/>}
          isLoading={uploading}
        />
      </form>
    </div>
  )
}

export default EditPost;