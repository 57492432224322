import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { url } from "../../utils/constants";
import { PostType } from "../action-types/post";
import { Action } from "../actions";
import { Post, PostAction } from "../actions/post";
import { setAlert } from "./alert";

interface PostResponse {
  ok: boolean,
  data: Array<Post>
}
interface SinglePostResponse {
  ok: boolean,
  data: Post
}
interface DataSinglePost{
  data: SinglePostResponse
}
interface Data{
  data: PostResponse
}

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
}
export const getPosts = () => async(dispatch: Dispatch<PostAction | Action>) => {
  dispatch({
    type: PostType.CLEAR_POSTS,
  })
  try {
    const {data: response}: Data  = await axios.get(`${url}/post`, config);
    console.log("response: ",response)
    dispatch({
      type: PostType.RETRIEVE_ALL_POSTS,
      payload: response.data
    })
  } catch (err) {
    let error = err as AxiosError;
    if(error.response) {
      dispatch({
        type: PostType.ERROR_POST,
        payload: {
          msg: error.response.data as string,
          status: error.response.status
        }
      })
    }
  }
}

export const getSinglePost = (id: string) => async (dispatch: Dispatch<PostAction | Action>) => {
  try {
    const {data: response}: DataSinglePost = await axios.get(`${url}/post/${id}`, config);
    console.log("single-post: ",response);
    dispatch({
      type: PostType.RETRIEVE_SINGLE_POST,
      payload: response.data
    })
  } catch (err) {
    let error = err as AxiosError;
    if (error.response) {
      dispatch({
        type: PostType.ERROR_POST,
        payload: {
          msg: error.response.data as string,
          status: error.response.status
        }
      })
    }
  }
}

export const addPost = (formData: Post) => async(dispatch: Dispatch<PostAction | Action | any>) => {
  try {
    const {data: response}: Data = await axios.post(`${url}/post`,formData ,config);
    console.log("res: ",response);
    dispatch({
      type: PostType.ADD_POST,
      payload: response.data
    })
    dispatch(setAlert("Proyecto Agregado", "success"));
  } catch (err) {
    let error = err as AxiosError | any;
    console.log("error: ",error.response.data);
    // const errors = error?.response?.data.msg;
    // if(errors){
      // error.forEach((error: any) => dispatch(setAlert(error.response.data.msg, 'error') as any));
    // }
    dispatch(setAlert(error.response.data.msg, 'error') as any);
    if(error.response) {
      dispatch({
        type: PostType.ERROR_POST,
        payload: {
          msg: error.response.data,
          status: error.response.status
        }
      })
    }
  }
}

export const updatePost = (id: string, formData: Post) => async(dispatch: Dispatch<PostAction | Action | any>) => {
  try {
    let {data:response}: Data = await axios.put(`${url}/post/${id}`,formData ,config);
    console.log("response: ",response)
    dispatch({
      type: PostType.EDIT_POST,
      payload: response.data,
    })
    dispatch(setAlert("Proyecto actualizado", "warning"));
  } catch (err) {
    let error = err as AxiosError | any;
    if (error.response){
      console.log("error: ",error.response.data.msg)
      dispatch(setAlert(error.response.data.msg, "error"));
      dispatch({
        type: PostType.ERROR_POST,
        payload: {
          msg: error.response.data,
          status: error.response.status
        }
      })
    }
  }
}

export const removePost = (id: string) => async(dispatch: Dispatch<PostAction | Action | any>) => {
  try {
    await axios.delete(`${url}/post/${id}`,config);
    dispatch({
      type:PostType.DELETE_POST,
      payload: id
    });
    dispatch(setAlert("Proyecto eliminado", "error"));
  } catch (err) {
    let error = err as AxiosError;
    if(error.response) {
      dispatch({
        type: PostType.ERROR_POST,
        payload: {
          msg: error.response.data,
          status: error.response.status
        }
      })
    }
  }
}

export const enablePost = (id: string, enabled: boolean) => async (dispatch: Dispatch<PostAction | Action | any>) => {
  try {
    const {data: response}: DataSinglePost = await axios.put(`${url}/post/enable/${id}`, {enabled}, config);
    if (enabled) {
      dispatch(setAlert("Proyecto habilitado", "success"));
    }else{
      dispatch(setAlert("Proyecto deshabilitado", "warning"));
    }
    dispatch({
      type: PostType.ENABLE_POST,
      payload: response.data
    })
  } catch (err) {
    let error = err as AxiosError;
    if (error.response) {
      dispatch({
        type: PostType.ERROR_POST,
        payload: {
          msg: error.response.data as string,
          status: error.response.status
        }
      })
    }
  }
}