import { faMailReply } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Button from '../../../components/common/Button'
import { Contact } from '../../../state/actions/contact'
import { getGravatarUrl, parseTimestampGmail } from '../../../utils/helpers'

const Message = ({fullname, email, timestamp, subject, message, phonenumber, favorite}: Contact) => {
  return (
    <div className="md:bg-gray-200 rounded-md md:shadow-md p-0 md:p-8">
      <div className="mx-auto w-full">
        <div className="bg-white overflow-hidden rounded-md flex flex-col md:flex-row justify-between  items-start md:items-center p-4">
          <div className="flex items-center">
            <img className="h-10 w-10 rounded-full" src={getGravatarUrl(email)} alt="Profile Image" />
            <div className="ml-4 flex flex-col">
              <h2 className="text-sm font-medium text-gray-800">{fullname}</h2>
              <a href={`mailto:${email}`} target="_blank" className="text-sm font-medium text-gray-500 hover:text-gray-600">{email}</a>
              <span className="text-sm font-medium text-gray-500 hover:text-gray-600">{phonenumber}</span>
            </div>
          </div>
          {/* <div className=''>   */}
            <span className='text-gray-400 mt-2 text-sm md:text-md'>{parseTimestampGmail(timestamp)}</span>
          {/* </div> */}
        </div>
        <div className="bg-white overflow-hidden rounded-md mt-4">
          <div className="p-4">
            <h3 className="text-md md:text-lg font-medium text-gray-800 leading-5">{subject}</h3>
            <p className="mt-2 text-sm text-gray-600">{message}</p>
          </div>
        </div>
        <div className='flex justify-end overflow-hidden mt-4 w-fit md:w-auto'>
          <a href={`mailto:${email}`} target="_blank" className='flex w-full justify-end'>
            <Button type='button' label='Responder' icon={<FontAwesomeIcon icon={faMailReply}/>}/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Message