import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Footer from '../../components/common/Footer'
import WaveSeparator from '../../components/common/WaveSeparator'
import CustomHead from '../../components/CustomHead'
import { menuItems } from '../../components/Layout/WebsiteLayout'
import Card from '../../components/Posts/Card'
import MainCard from '../../components/Posts/MainCard'
import { RootState } from '../../state'
import { getAbout } from '../../state/action-creators/about'
import { getPosts } from '../../state/action-creators/post'
import { getSettings } from '../../state/action-creators/settings'
import { AboutState } from '../../state/actions/about'
import { Post, PostState } from '../../state/actions/post'
import { SettingsState } from '../../state/actions/settings'
import ContactoForm from './Contacto/ContactoForm'

const Home = ({hideNavbar = false}: {hideNavbar?: boolean}) => {
  const {posts, loading} = useSelector((state: RootState) => state.post) as PostState;
  const {settings} = useSelector((state: RootState) => state.settings) as SettingsState;
  const {about} = useSelector((state: RootState) => state.about) as AboutState;
  const [enabledPosts, setEnabledPosts] = useState<Post[]>([])
  const [visible, setVisible] = useState(4);
  const dispatch = useDispatch();
  useEffect(() => {
    const retrievePosts = () => dispatch(getPosts() as any);
    retrievePosts();
  },[dispatch]);
  
  useEffect(() => {
    const retrieveAbout = () => dispatch(getAbout() as any);
    retrieveAbout();
  },[dispatch]);

  useEffect(() => {
    const retrieveSettings = () => dispatch(getSettings() as any);
    retrieveSettings();
  },[dispatch]);

  useEffect(() => {
    if (!loading && posts.length > 0){
      let filteredPosts = posts.filter(post => post.enabled);
      setEnabledPosts(filteredPosts);
    }
  },[posts, loading])

  console.log("posts: ",posts);
  return (
    <>
      <CustomHead title='Revuelo Arts - Home'/>
      <div className='w-full h-screen'>
        <div className="relative h-inherit wrapper-banner">
          <div className='w-full img-banner bg-white h-fit py-20 md:pt-10 md:pb-20 bg-home'
          style={{backgroundImage: `url(${settings?.homebackground})`}}
          >
            <div className="w-full h-fit flex items-start justify-center mb-0">
              <div className="my-0 p-0 px-8 text-white text-center w-full items-center">
                <div className='w-full grid grid-cols-1 md:grid-cols-3 place-content-center justify-center items-center place-items-center'>
                  <img src="/logos/revuelo-hexagon-logo.png" alt="main logo" className='hidden md:block object-fit justify-self-start' style={{width: '300px'}}/>
                  <img src="/logos/revuelo-main-logo.png" alt="main logo" className='' style={{width: '250px'}}/>
                  <div className='relative justify-self-end mr-4 z-10'>
                    <img src="/logos/revuelo-bird.png" alt="main logo" className='hidden md:block object-fit justify-self-end' style={{width: '150px', height: '150px'}}/>
                    <img src="/logos/revuelo-bird.png" alt="main logo" className='hidden md:block object-fit justify-self-end absolute top-5 right-0 mr-3' style={{width: '20px', height: '20px'}}/>
                  </div>
                </div>
                <ul className='hidden md:flex justify-center my-5 w-full md:w-full max-w-2xl mx-auto'>
                  {menuItems.map(({ href, title }) => (
                    <Link to={href} key={title} className="transition ease-in duration-150 font-nova-normal hover:bg-black-transparent text-white block px-3 py-2 rounded-md text-base font-medium">
                        {title}
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
            <WaveSeparator isWhite={true}/>
          </div>
        </div>
        <div className='py-14 md:py-14'>
          <div className='m-0 py-0 md:py-0 lg:px-28 px-5 md:px-8 w-auto flex flex-col lg:flex-row md:items-start flex justify-center'>
            <div className='lg:max-w-md w-full lg:w-fit text-start lg:text-center lg:p-10 flex flex-col text-gray-800 text-4xl md:text-5xl md:text-6xl font-berlin'>
              <h1 className='hidden lg:block'>
                CONOCE <br/>
                REVUELO <br/>
                ARTS
              </h1>
              <h1 className='block lg:hidden mb-4'>
                CONOCE REVUELO ARTS
              </h1>
            </div>
            <div className='w-full lg:max-w-3xl w-full lg:p-8 p-0 md:py-4 flex flex-col items-start md:items-start justify-center'>
              <p className='text-lg md:text-lg xl:text-xl text-gray-700 font-nova m-0'>
                {about?.about_home}
              </p>
              <Link to={'/nosotros'} className='font-berlin text-xl bg-gray-700 text-white duration-300 hover:bg-gray-600 text-white px-8 py-4 my-4'>
                  ACERCA DE NOSOTROS
              </Link>
            </div>
          </div>
        </div>
        <div className='bg-gray-100 py-14'>
          <div className='mx-5 py-0 md:py-12 lg:px-28 px-0 md:px-8 w-auto flex flex-col items-center flex justify-center'>
            <h1 className='font-berlin text-4xl m-0 md:mb-10'>NUESTROS PROYECTOS</h1>
            <div>

            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full'>
                {
                  (!loading && enabledPosts.length > 0) && enabledPosts.slice(0,visible).map((project, key) => (
                    <>
                      {
                        (key === 0) ?
                          <MainCard {...project} key={project._id}/> :  
                          <Card {...project} key={project._id}/> 
                      }
                    </>
                  ))
                }
            </div>
            <Link to={'/proyectos'} className='font-berlin text-xl bg-gray-700 text-white duration-300 hover:bg-gray-600 text-white px-8 py-4 my-4 w-full md:w-fit text-center'>
                VER MAS PROYECTOS
            </Link>
          </div>
        </div>
        
        <div className='py-14 m-5 md:py-12 lg:px-28 px-0 md:px-8 w-auto flex flex-col items-center flex justify-center'>
          <div className='mb-10 text-center'>
            <h1 className='font-berlin text-4xl md:mb-6'>Contáctanos</h1>
            <p className='m-0 text-lg'>Escríbenos y te responderemos lo más pronto posible.</p>
          </div>
          <ContactoForm/>
        </div>
        <Footer isFromWebsite={true}/>
      </div>
    </>
  )
}

export default Home