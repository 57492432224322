import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Input from '../../../components/common/Input';
import TextArea from '../../../components/common/TextArea';
import { addContact } from '../../../state/action-creators/contact';
import { Contact } from '../../../state/actions/contact';

const ContactoForm = () => {
  const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm<Contact>();
  const [formDisabled, setFormDisabled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();
  async function handleOnSubmit(data: Contact, setFormDisabled: (disabled: boolean) => void){
    console.log("data: ",data);
    await dispatch(addContact(data) as any);
    cleanInputs();
    setFormSubmitted(true);
  }
  const cleanInputs = () => {
    setValue('firstname', '')
    setValue('lastname', '')
    setValue('phonenumber', '')
    setValue('email', '')
    setValue('subject', '')
    setValue('message', '')
  }
  return (
    <form onSubmit={handleSubmit((data) => handleOnSubmit(data, setFormDisabled))} className='w-full border border-border p-6 rounded-md bg-grey shadow-md'>
      {formSubmitted ? (
        <div className="flex items-center justify-center w-full text-lg">
          {/* <img src="/path/to/image.png" alt="Mensaje Enviado" className="mr-2" /> */}
          <FontAwesomeIcon icon={faCheckCircle} className='text-gray-800 mr-2'/>
          <span>Mensaje Enviado</span>
        </div>
      ) :
      <>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
            <Input name='firstname' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label="Nombres" errors={errors} showRequired={false}/>
            <Input name='lastname' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label="Apellidos" errors={errors} showRequired={false}/>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
            <Input name='phonenumber' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label="Telefono" errors={errors} showRequired={false}/>
            <Input name='email' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label="Email" errors={errors} showRequired={false}/>
        </div>
        <div className='grid-cols-1'>
            <Input name='subject' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label="Asunto" errors={errors} showRequired={false}/>
        </div>
        <div className='grid-cols-1'>
            <TextArea name='message' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label='Mensaje' errors={errors} value={watch('message')} showRequired={false}/>
        </div>
        <div className="flex items-center justify-end w-full">
            <button type="submit" className="font-berlin text-xl bg-gray-700 text-white duration-300 hover:bg-gray-600 text-white px-8 py-4 my-4 mb-0 w-full md:w-fit">Enviar</button>
        </div>
      </>
      }
      
    </form>
  )
}

export default ContactoForm