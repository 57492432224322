import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import RedirectTo from '../../../components/common/RedirectTo'
import CustomHead from '../../../components/CustomHead'
import Paginator from '../../../components/Posts/Paginator'

const Posts = () => {
  return (
    <div className='p-4 md:p-4'>
      <CustomHead title="Revuelo - Proyectos"/>
      <RedirectTo text="Agregar Proyecto" to="/admin/posts/new" icon={<FontAwesomeIcon icon={faPlus}/>}/>
      <Paginator itemsPerPage={4} isFromWebsite={false}/>
    </div>
  )
}

export default Posts