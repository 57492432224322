import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/common/Button';
import FileUpload from '../../components/common/FileUpload';
import Input from '../../components/common/Input';
import Loading from '../../components/common/Loading';
import TextArea from '../../components/common/TextArea';
import CustomHead from '../../components/CustomHead';
import { RootState } from '../../state';
import { addAbout, getAbout } from '../../state/action-creators/about';
import { About, AboutState } from '../../state/actions/about';
import { url } from '../../utils/constants';

const Nosotros = () => {
  const [formDisabled, setFormDisabled] = useState(false);
  const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm<About>();
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const {about, loading} = useSelector((state: RootState) => state.about) as AboutState;
  const [nosotrosImage, setNosotrosImage] = useState<{images: File[]}>({
    images: []
  });
  async function handleOnSubmit(data: About, setFormDisabled: (disabled: boolean) => void){
    let uploadedData = await uploadFileHandler();
    try {
      setFormDisabled(true); // Disable the form
      let data_to_send = {
        title: data.title,
        body: data.body,
        about_home: data.about_home,
        image: uploadedData[0]
      };
      dispatch(addAbout(data_to_send) as any);
    }catch(error){
      console.log("Error: ",error);
    } finally {
      setFormDisabled(false); // Re-enable the form
    }
  }
  useEffect(() => {
    const retrieveAbout = () => dispatch(getAbout() as any);
    retrieveAbout();
  },[dispatch])
  useEffect(() => {
    if(!loading && about?.title && about?.body) {
      setValue('title', about.title);
      setValue('body', about.body);
      setValue('about_home', about.about_home);
      setValue('image', about.image);
    }
  },[loading, about]);
  useEffect(() => {
    if(about && about.image && about.image.length > 0){
      let images = about.image.map(async function(image: string){
        const response = await fetch(`${image}`);
        var blob = await response.blob();
        return new File([blob], image, { type: blob.type });
      }) || []
      Promise.all(images).then((res) => updateUploadedFiles(res));
    }
  }, [about, loading])
  const updateUploadedFiles = (files: Array<File>) => setNosotrosImage({ ...nosotrosImage, images: files });
  const uploadFileHandler = async () => {    
    const files = [...nosotrosImage.images];
    if (files) {
      const formData = new FormData();
      files.forEach(function(file){
        formData.append("image", file);
      })
      setUploading(true);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post(`${url}/upload`, formData, config);
        console.log("loaded-image: ",data);
        setUploading(false);
        return data;
      } catch (error) {
        setUploading(false);
      }
    }
  };
  return (
    loading ? <Loading/> :
    <div className="p-4 md:p-8 flex flex-col lg:w-3/4 sm:w-full w-full m-auto h-screen md:h-auto">
      <CustomHead title='Revuelo Admin - Crear Proyecto'/>
      <div className="flex items-center gap-x-5 mb-2 w-full justify-between">
        <h1 className="text-lg md:text-2xl text-gray-800 font-medium">Revuelo - Nosotros</h1>
      </div>
      <form method="post" onSubmit={handleSubmit((data) => handleOnSubmit(data, setFormDisabled))} className="border p-4 md:p-8 rounded-md self-center w-full bg-gray-50 shadow-md" aria-disabled={formDisabled}>
        <Input name='title' register={register} registerOptions={{required: 'Este campo es obligatorio'}} label="Titulo" errors={errors}/>
        <TextArea name='body' value={watch('body')} register={register} registerOptions={{required: 'Este campo es obligatorio', maxLength: {
          value: 1200,
          message: 'Maximo 1200 Caracteres'
        }}} label="Vista Previa de Descripcion" errors={errors}/>
        <TextArea name='about_home' value={watch('about_home')} register={register} registerOptions={{required: 'Este campo es obligatorio', maxLength: {
          value: 300,
          message: 'Maximo 300 Caracteres'
        }}} label="Descripción Homepage" errors={errors}/>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">Imagen (Sólo PNG/JPG/JPEG)</label>
          <FileUpload label="Profile Image(s)" 
                      multiple={false}
                      updateFilesCb={updateUploadedFiles}
                      images={nosotrosImage.images}
          />
        </div>
        <Button 
          type='submit' 
          label={'Guardar cambios'} 
          icon={<FontAwesomeIcon icon={faSave}/>}
          isLoading={uploading}
        />
      </form>
    </div>
  )
}

export default Nosotros