// process.env.NODE_ENV = 'production';

const env = process.env.REACT_APP_NODE_ENV;
console.log("env: ", env);

export const baseUrl = env === "production" ? `https://revueloarts.com` : `http://localhost:8000`;
// export const baseUrl = "https://api.revueloarts.com";
// export const baseUrl = "http://localhost:8000";
export const url = `${baseUrl}/api`;

// console.log("url: ",url);
// export const imagesUrl = `https://revueloarts.com`;
