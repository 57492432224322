import md5 from 'crypto-js/md5';

const validateYoutubeUrl = (url: string) => {
  // const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*v=([^\s&]+)/;
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtube-nocookie\.com)\/.+$/;
  const match = url.match(youtubeRegex);
  return match !== null;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function parseTimestamp(timestamp: string) {
  const date = new Date(timestamp);
  const now = new Date();
  if (date.getFullYear() !== now.getFullYear()) {
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
  }
  const isToday =
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear();

  if (isToday) {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit"
    });
  } else {
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit"
    });
  }
}

function parseTimestampGmail(timestamp: string): string {
  const date = new Date(timestamp);
  const hour12 = date.getHours() % 12 || 12;
  const minute = date.getMinutes().toString().padStart(2, "0");
  const amPm = date.getHours() >= 12 ? "PM" : "AM";

  const now = new Date();
  const elapsedMs = now.getTime() - date.getTime();
  const elapsedMinutes = Math.floor(elapsedMs / 60000);

  let elapsed = "";
  if (elapsedMinutes < 1) {
    elapsed = "just now";
  } else if (elapsedMinutes < 60) {
    elapsed = `${elapsedMinutes} minutes ago`;
  } else if (elapsedMinutes < 1440) {
    const hours = Math.floor(elapsedMinutes / 60);
    elapsed = `${hours} ${hours > 1 ? "hours" : "hour"} ago`;
  } else {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    elapsed = `${month} ${day}, ${year}`;
  }

  return `${date.toLocaleString("default", {
    month: "short",
    day: "numeric",
    year: "numeric",
  })}, ${hour12}:${minute} ${amPm} (${elapsed})`;
}

function getGravatarUrl(email: string, size: number = 80) {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=mp`;
}

export {
  validateYoutubeUrl,
  classNames,
  parseTimestamp,
  parseTimestampGmail,
  getGravatarUrl
};
