import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../components/common/Footer'
import WaveSeparator from '../../components/common/WaveSeparator'
import CustomHead from '../../components/CustomHead'
import Paginator from '../../components/Posts/Paginator'
import { RootState } from '../../state'
import { getSettings } from '../../state/action-creators/settings'
import { SettingsState } from '../../state/actions/settings'

const Proyectos = () => {
  const {settings} = useSelector((state: RootState) => state.settings) as SettingsState;
  const dispatch = useDispatch();
  useEffect(() => {
    const retrieveSettings = () => dispatch(getSettings() as any);
    retrieveSettings();
  },[dispatch]);
  return (
    <div className='w-full h-screen'>
      <CustomHead title='Revuelo Arts - Proyectos'/>
      <div className="relative wrapper-banner">
        <div className='w-full img-banner bg-grey h-fit py-12 bg-projects'
            style={{backgroundImage: `url(${settings?.proyectobackground})`}}
          >
          <div className="w-full h-fit flex items-center justify-center mb-0">
            <div className=" py-20 px-8 w-auto md:w-full max-w-1xl text-white text-center">
              <h1 className='font-berlin text-3xl md:text-4xl lg:text-5xl header-title'>Nuestros Proyectos</h1>
            </div>
          </div>
          <WaveSeparator isWhite={true}/>
        </div>
      </div>
      
      <div className='m-5 py-0 md:py-12 lg:px-20 px-0 md:px-8 w-auto'>
        <Paginator itemsPerPage={6} isFromWebsite={true}/>
      </div> 
      <Footer isFromWebsite={true}/>
    </div>
  )
}

export default Proyectos