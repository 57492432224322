
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../state';
import { getPosts } from '../../state/action-creators/post';
import { Post, PostState } from '../../state/actions/post';
import Loading from '../common/Loading';
// import { CreatePostInput } from '../../schema/post.schema';
// import { trpc } from '../../utils/trpc';
import ArticleCard from './ArticleCard';
import Card from './Card';
export function Items({ currentItems, isFromWebsite }: { currentItems: Post[], isFromWebsite?: boolean }) {
  return (
    <>
      {
        currentItems.map((post: Post, idx: number) => (
          <div key={post._id}>
            {
              (isFromWebsite) ? 
              <Link to={`/proyectos/${post._id}`} className="bg-transparent text-left">
                <Card {...post}/>
              </Link> :
              <ArticleCard {...post}/>
            }
          </div>
        )) 
      }
    </>
  );
}

function Paginator({ itemsPerPage, isFromWebsite = false }: { itemsPerPage: number, isFromWebsite?: boolean}) {
  const {posts, loading} = useSelector((state: RootState) => state.post) as PostState;
  const [dataToFetch, setDataToFetch] = useState<Array<Post>>();
  const [enabledPosts, setEnabledPosts] = useState<Post[]>([])
  const dispatch = useDispatch();
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState<any>();
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Invoke when user click to request another page.
  const handlePageClick = (event: {selected: number}) => {
    const newOffset = (dataToFetch) && (event.selected * itemsPerPage) % dataToFetch.length;
    setItemOffset(newOffset || 0);
  };
  useEffect(() => {
    if (!loading && posts.length > 0){
      let filteredPosts = posts.filter(post => post.enabled);
      setEnabledPosts(filteredPosts);
    }
  },[posts, loading])
  useEffect(() => {
    if(!loading && posts && !isFromWebsite){
      setDataToFetch(posts);
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(posts.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(posts.length / itemsPerPage));
    }else if(!loading && enabledPosts && isFromWebsite){
      setDataToFetch(enabledPosts);
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(enabledPosts.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(enabledPosts.length / itemsPerPage));
    }
  }, [posts, itemOffset, itemsPerPage, loading, enabledPosts])
  useEffect(() => {
    const retrievePosts = () => dispatch(getPosts() as any);
    retrievePosts();
  },[dispatch]);
  console.log("posts: ",posts);
  return (
    <>
      {
        loading ? <Loading /> :
        <>
          <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 ${(isFromWebsite) ? 'lg:p-8 lg:grid-cols-3' : 'lg:grid-cols-4'} gap-4 w-auto items-start`}>
            {
              (currentItems) && <Items currentItems={currentItems} isFromWebsite={isFromWebsite} />
            }
          </div>
          <div className='w-full'>
            <ReactPaginate
              previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
              nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              breakLabel={"..."}
              marginPagesDisplayed={2}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </div>
        </>
      }
    </>
  );
}

export default Paginator