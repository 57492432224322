import React from 'react'
import { Link } from 'react-router-dom'

interface IButton{
  to?: string,
  text?: string,
  onClick?: () => void,
  icon?: JSX.Element
}

const RedirectTo = ({ to = "", text = "", onClick, icon }: IButton) => {
  return (
    <Link to={to} className="w-full text-center lg:text-left sm:w-fit inline-block py-4 px-5 bg-gray-800 text-white font-normal text-sm leading-tight rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out text-base leading-none mb-2" onClick={onClick}>
      {
        (icon) && <span className='mr-2'> {icon} </span>
      }
      {text}
    </Link>
  )
}

export default RedirectTo