import React, { Dispatch, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppLayout from './components/Layout/AppLayout';
import { PrivateRouter } from './routing/PrivateRoute';
import { store } from './state';
import { AuthType } from './state/action-types/auth';
import Alerts from './utils/Alert';
import Configuracion from './views/admin/Configuracion/Configuracion';
import Contacto from './views/admin/Contacto/Contacto';
import ContactoMessage from './views/admin/Contacto/ContactoMessage';
import Login from './views/admin/Login';
import Nosotros from './views/admin/Nosotros';
import EditPost from './views/admin/Posts/EditPost';
import NewPost from './views/admin/Posts/NewPost';
import Posts from './views/admin/Posts/Posts';
import ViewPost from './views/admin/Posts/ViewPost';
import EditUser from './views/admin/Users/EditUser';
import NewUser from './views/admin/Users/NewUser';
import Users from './views/admin/Users/Users';
import ContactoWeb from './views/website/Contacto/Contacto';
import Home from './views/website/Home';
import NosotrosWeb from './views/website/Nosotros';
import Proyectos from './views/website/Proyectos';
function App() {
  const dispatchStore = store.dispatch as typeof store.dispatch | Dispatch<any> | any;
  // const navigate = useNavigate();
  useEffect(() => {
    // check for token in LS when app first runs
    // const fetchData = async () => {
    //   if (localStorage.token) {
    //     setAuthToken(localStorage.token);
    //   }
    //   await dispatchStore(loadUser());
    // };
  
    // fetchData();
    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: AuthType.LOGOUT });
    });
  }, [dispatchStore]);
  
  return (
    <Provider store={store}>
      <Router>
        <Alerts/>
        <Routes>
          <Route path='/' element={<AppLayout isFromWebsite={true} showNavbar={false} />}>  
            <Route path='/' element={<Home/>}/>
            <Route path='/nosotros' element={<NosotrosWeb/>}/>
            <Route path='/contacto' element={<ContactoWeb/>}/>
            <Route path='/proyectos' element={<Proyectos/>}/>
            <Route path='/proyectos/:id' element={<ViewPost/>}/>
          </Route>
          <Route path='/admin/login' element={<Login/>}/>
          <Route path='/' element={<AppLayout/>}> 
              <Route path='/admin/users' element={<PrivateRouter component={Users} />}/>
              <Route path='/admin/users/new' element={<PrivateRouter component={NewUser} />}/>
              <Route path='/admin/users/edit/:id' element={<PrivateRouter component={EditUser} />}/>
              <Route path='/admin/posts' element={<PrivateRouter component={Posts} />}/>
              <Route path='/admin/posts/new' element={<PrivateRouter component={NewPost} />}/>
              <Route path='/admin/posts/view/:id' element={<PrivateRouter component={ViewPost} />}/>
              <Route path='/admin/posts/edit/:id' element={<PrivateRouter component={EditPost} />}/>
              <Route path='/admin/nosotros' element={<PrivateRouter component={Nosotros} />}/>
              <Route path='/admin/contacto' element={<PrivateRouter component={Contacto} />}/>
              <Route path='/admin/contacto/:id' element={<PrivateRouter component={ContactoMessage} />}/>
              <Route path='/admin/configuracion' element={<PrivateRouter component={Configuracion} />}/>
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
