import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ISocialMedia {
  icon: JSX.Element,
  placeholder: string,
  index: number,
  onChange: (index: number, e: React.ChangeEvent<HTMLInputElement>) => void,
  isYoutubeUrlValid: boolean,
  isTouched: boolean,
  removeInputField: (index: number) => void,
  value?: string,
}
const SocialMediaInput = ({icon, placeholder, onChange, index, isYoutubeUrlValid, removeInputField, isTouched, value = ""}:ISocialMedia) => {
  
  return (
    <>
      <div className='w-full border border-border rounded-md p-2 px-4 flex items-center gap-x-2 bg-white'>
        {icon}
        <div className='w-full flex gap-x-2'>
          <input type="text" 
            placeholder={placeholder} 
            className='w-full border-none outline-none bg-transparent px-2'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(index, e)}
            value={value}
          />
        </div>
        <button type='button' onClick={() => removeInputField(index)} className='p-2 px-3 bg-red-500 text-white rounded-md hover:bg-red-600'>
          <FontAwesomeIcon icon={faMinus} size='1x'/>
        </button>
      </div>

      {
        isTouched && !isYoutubeUrlValid && (
          <label className='text-red-400 text-sm'>Invalid YouTube URL</label>
        )
      }
    </>
  )
}

export default SocialMediaInput