import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../components/common/Loading'
import RedirectTo from '../../../components/common/RedirectTo'
import CustomHead from '../../../components/CustomHead'
import { RootState } from '../../../state'
import { getUsers, removeUser } from '../../../state/action-creators/user'
import { User, UserState } from '../../../state/actions/user'
import Table from './UserTable'

const Users = () => {
  const {users, loading} = useSelector((state: RootState) => state.user) as UserState;
  const [revueloUsers, setRevueloUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columns = React.useMemo(
    () => [
      {
        Header: "Nombres",
        accessor: "fullname",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Acciones",
        accessor: "actions",
      },
    ],
    []
  );
  const handleDelete = async (id: string) => {    
    const confirmed = window.confirm("¿Estás seguro de eliminar este usuario?")
    if (confirmed) {
      await dispatch(removeUser(id) as any);
    }
    return;
  };
  function prepareData(data: any) {
    return data.map((row: any) => {
      return {
        ...row,
        handleDelete: (_id: string) => {
          handleDelete(_id);
        },
        handleEdit: (_id: string) => navigate(`/admin/users/edit/${_id}`)
      }
    });
  }
  useEffect(() => {
    if(!loading && users.length > 0){
      setRevueloUsers(prepareData(users));
    }
  },[loading, users])
  useEffect(() => {
    const retrieveContacts = () => dispatch(getUsers() as any);
    retrieveContacts();
  },[dispatch])
  return (
    <div className="p-4 md:p-8 flex flex-col lg:w-3/4 sm:w-full w-full m-auto h-screen md:h-auto">
      <CustomHead title="Revuelo - Usuarios"/>
      <div className="flex items-center gap-x-5 w-full justify-between items-center">
        <RedirectTo text="Agregar Usuario" to="/admin/users/new" icon={<FontAwesomeIcon icon={faPlus}/>}/>
        <h1 className="text-lg md:text-2xl text-gray-800 font-medium">Revuelo - Usuarios</h1>
      </div>
        {
          loading ? <Loading /> :
          <Table columns={columns} data={revueloUsers}/>
        }
    </div>
  )
}

export default Users