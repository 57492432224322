import { Helmet } from 'react-helmet';
// import favicon from '../../public/revuelo-white.ico';
// import { version } from "../../package.json";

type Props = {
  title?: string;
};

const CustomHead = ({ title = 'Revuelo Arts' }: Props) => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta name="description" content="Official website for... something." />
    <link rel="shortcut icon" href={'/revuelo-white.ico'} type="image/x-icon" />
    <title>{title}</title>
  </Helmet>
);

export default CustomHead;