import { faChevronDown, faChevronUp, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import Loading from '../../../components/common/Loading';
import CustomHead from '../../../components/CustomHead';
import { RootState } from '../../../state';
import { addSettings, getSettings } from '../../../state/action-creators/settings';
import { Settings, SettingsState } from '../../../state/actions/settings';
import { url } from '../../../utils/constants';
import BackgroundManager from './BackgroundManager';

export interface IBackground {
  homebackground: File | undefined,
  aboutbackground: File | undefined,
  proyectobackground: File | undefined,
  contactbackground: File | undefined
  proyectoindividualbackground: File | undefined
}

const Configuracion = () => {
  const [formDisabled, setFormDisabled] = useState(false);
  const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm<Settings>();
  const {settings, loading} = useSelector((state: RootState) => state.settings) as SettingsState;
  const [uploading, setUploading] = useState(false);
  const [toggleShow, setToggleShow] = useState(false);
  const [activeTab, setActiveTab] = useState('home');
  const [imagesFiles, setImagesFiles] = useState<IBackground>({
    homebackground: undefined,
    aboutbackground: undefined,
    proyectobackground: undefined,
    contactbackground: undefined,
    proyectoindividualbackground: undefined
  });
  const dispatch = useDispatch();
  async function handleOnSubmit(data: Settings, setFormDisabled: (disabled: boolean) => void){
    try {
      let fileUpload = await uploadFileHandler();
      console.log("fileUpload: ",fileUpload);
      setFormDisabled(true); // Disable the form
      let data_to_send = {
        homebackground: fileUpload['homebackground'] || "",
        aboutbackground: fileUpload['aboutbackground'] || "",
        proyectobackground: fileUpload['proyectobackground'] || "",
        contactbackground: fileUpload['contactbackground'] || "",
        proyectoindividualbackground: fileUpload['proyectoindividualbackground'] || "",
        mail: data.mail,
        facebook: data.facebook,
        instagram: data.instagram,
        youtube: data.youtube,
        tiktok: data.tiktok,
        twitter: data.twitter
      } as Settings
      console.log("data_to_send: ",data_to_send);
      // return;
      dispatch(addSettings(data_to_send) as any);
    }catch(error){
      console.log("Error: ",error);
    } finally {
      setFormDisabled(false); // Re-enable the form
    }
  }
  useEffect(() => {
    const retrieveAbout = () => dispatch(getSettings() as any);
    retrieveAbout();
  },[dispatch])
  
  useEffect(() => {
    if(!loading && settings) {
      setValue('mail', settings?.mail || "");
      setValue('facebook', settings?.facebook || "");
      setValue('instagram', settings?.instagram || "");
      setValue('youtube', settings?.youtube || "");
      setValue('tiktok', settings?.tiktok || "");
      setValue('twitter', settings?.twitter || "");
      setValue('homebackground', settings?.homebackground || "");
      setValue('aboutbackground', settings?.aboutbackground || "");
      setValue('proyectobackground', settings?.proyectobackground || "");
      setValue('contactbackground', settings?.contactbackground || "");
      setValue('proyectoindividualbackground', settings?.proyectoindividualbackground || "");
    }
  },[loading, settings])
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const updateUploadedFiles = useCallback((fieldName: string, files: Array<File>) => {
    setImagesFiles((prevState) => ({
      ...prevState,
      [fieldName]: files[0], // Assuming you only want to handle one file per field
    }));
  }, []);
  

  const uploadFileHandler = async () => {    
    // const files = [...imagesFiles.images];
    // if (files) {
      setUploading(true);
      try {
        const formData = new FormData();
        Object.entries(imagesFiles).forEach(([fieldName, file]: any) => {
          if (file) {
            formData.append(fieldName, file);
          }
        });
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post(`${url}/upload/settings`, formData, config);
        console.log("loaded-image: ",data);
        setUploading(false);
        return data;
      } catch (error) {
        setUploading(false);
      }
    // }
  };
  
  useEffect(() => {
    const fetchBackgroundImages = async () => {
      if (!loading) {
        const backgroundImages = [
          { fieldName: 'homebackground', imageUrl: settings?.homebackground },
          { fieldName: 'aboutbackground', imageUrl: settings?.aboutbackground },
          { fieldName: 'proyectobackground', imageUrl: settings?.proyectobackground },
          { fieldName: 'contactbackground', imageUrl: settings?.contactbackground },
          { fieldName: 'proyectoindividualbackground', imageUrl: settings?.proyectoindividualbackground },
        ];
        const images = await Promise.all(
          backgroundImages
            .filter((image) => image.imageUrl)
            .map(async (image) => {
              const response = await fetch(image.imageUrl!);
              console.log("response: ",response);
              const blob = await response.blob();
              return { fieldName: image.fieldName, file: new File([blob], image.imageUrl!, { type: blob.type }) };
            })
        );
  
        const filesByFieldName = images.reduce((acc, { fieldName, file }) => {
          return { ...acc, [fieldName]: file };
        }, {}) as any;
  
        for (const fieldName in filesByFieldName) {
          updateUploadedFiles(fieldName, [filesByFieldName[fieldName]]);
        }
      }
    };
    
    fetchBackgroundImages();
  }, [settings, loading, updateUploadedFiles])
  console.log("imagesFiles: ",imagesFiles);
  // console.log("files: ", imagesFiles);
  return (
    loading ? <Loading /> :
    <div className="p-4 md:p-8 flex flex-col lg:w-3/4 sm:w-full w-full m-auto h-screen md:h-auto">
      <CustomHead title="Revuelo - Configuración"/>
      <div className="flex items-center gap-x-5 mb-2 w-full justify-between">
        <h1 className="text-lg md:text-2xl text-gray-800 font-medium">Revuelo - Configuración</h1>
      </div>
      <form method="post" onSubmit={handleSubmit((data) => handleOnSubmit(data, setFormDisabled))} className="border p-4 md:p-8 rounded-md self-center w-full bg-gray-50 shadow-md" aria-disabled={formDisabled}>
        <BackgroundManager updateUploadedFiles={updateUploadedFiles} imagesFiles={imagesFiles}/>
        <a href="#" onClick={() => setToggleShow(!toggleShow)} className='flex items-center gap-2 text-gray-600 hover:text-gray-700 mb-4'>
          Mostrar Redes Sociales
          {
            toggleShow ? 
            <FontAwesomeIcon icon={faChevronUp}/> :
            <FontAwesomeIcon icon={faChevronDown}/>
          }
        </a>
        <div className={`${toggleShow ? 'block' : 'hidden'}`}>
          <Input name='mail' register={register} label="Email" errors={errors} placeholder={'Email'}/>
          <Input name='facebook' register={register} label="Facebook URL" errors={errors} placeholder={'https://www.facebook.com/username'}/>
          <Input name='instagram' register={register} label="Instagram URL" errors={errors} placeholder={'https://www.instagram.com/username'}/>
          <Input name='youtube' register={register} label="Youtube URL" errors={errors} placeholder={'https://www.youtube.com/@username'}/>
          <Input name='tiktok' register={register} label="Tiktok URL" errors={errors} placeholder={'https://www.tiktok.com/@username'}/>
          <Input name='twitter' register={register} label="Twitter URL" errors={errors} placeholder={'https://twitter.com/username'}/>
        </div>
        <Button
          type='submit' 
          label={'Guardar cambios'} 
          icon={<FontAwesomeIcon icon={faSave}/>}
          isLoading={uploading}
        />
      </form>
    </div>
  )
}

export default Configuracion