// import { useRouter } from 'next/router';
// import RevueloLogo from '../../Images/Logos/revuelo-white.png';
// import {FcAbout} from 'react-icons/fc';
// import {AiFillHome,AiFillContacts, AiFillSetting, AiOutlineMenu} from 'react-icons/ai'
// import {BsFillBookFill} from 'react-icons/bs'
// import Link from 'next/link'
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import React, { useRef, useState } from 'react';
// import revueloLogo from '../Images/Logos/revuelo-white-text.png';
// import Footer from '../components/Footer';
// import Image from 'next/image';
import { Link, useLocation } from 'react-router-dom';
type Props = {
  children?: JSX.Element | JSX.Element[];
};

export const menuItems = [
  {
    href: '/',
    title: 'INICIO',
    // icon: <AiFillHome className="w-5 h-5 fill-current dark:text-gray-400"/>
  },
  {
    href: '/nosotros',
    title: 'NOSOTROS',
    // icon: <BsFillBookFill className="w-5 h-5 fill-current dark:text-gray-400"/>
  },
  {
    href: '/proyectos',
    title: 'PROYECTOS',
    // icon: <AiFillHome className="w-5 h-5 fill-current dark:text-gray-400"/>
  },
  {
    href: '/contacto',
    title: 'CONTÁCTANOS',
    // icon: <AiFillContacts className="w-5 h-5 fill-current dark:text-gray-400"/>
  },
  {
    href: '/admin/login',
    title: 'ACCEDER',
    // icon: <AiFillContacts className="w-5 h-5 fill-current dark:text-gray-400"/>
  }
];

export default function Navbar({ children }: Props) {
  const [navbar, setNavbar] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  console.log("location: ",location.pathname);
  return (
    <div>
      <nav className="absolute w-full z-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-max p-5">
            <div className={`${location.pathname === '/' ? 'md:hidden' : 'flex items-center'}`}>
              <Link to='/' className={`${location.pathname === '/' ? 'hidden' : ''} md:block flex-shrink-0`}>
                <img src={'/logos/revuelo-main-logo.png'} alt="Revuelo Logo" className='revuelo-logo' />
              </Link>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                {menuItems.map(({ href, title }) => (
                  <Link to={href} key={title} className='transition ease-in duration-150 font-nova-normal hover:bg-black-transparent text-white block px-3 py-2 rounded-md text-base font-medium'>
                    {title}
                  </Link>
                ))}
                </div>
              </div>
            </div>
            <div className="flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="border border-gray-500 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-0"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <FontAwesomeIcon icon={faBars} className="block h-6 w-6"/>
                ) : (
                  <FontAwesomeIcon icon={faClose} className="block h-6 w-6"/>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {
            <div className="md:hidden bg-gray-900" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {menuItems.map(({ href, title }) => (
                  <Link to={href} key={title} className='bg-gray-800 hover:bg-gray-600 text-white block px-3 py-2 rounded-md text-base font-medium text-center text-md' onClick={() => setIsOpen(!isOpen)}>
                      {title}
                  </Link>
                ))}
              </div>
            </div>
          }
        </Transition>
      </nav>
      <main>
        <div className="w-full">
          {children}
        </div>
      </main>
    </div>
  );
}