import { AboutType } from "../action-types/about";
import { AboutAction, AboutState } from "../actions/about";

const initialState: AboutState = {
  about: null,
  loading: true,
  error: null,
}

const reducer = (state: AboutState = initialState, action: AboutAction) => {
  switch(action.type){
    case AboutType.RETRIEVE_ALL_ABOUT:
      return {
        ...state,
        about: action.payload,
        loading: false,
        error: null,
      }
    case AboutType.ADD_EDIT_ABOUT:
      return {
        ...state,
        about: action.payload,
        loading: false,
        error: null
      }
    case AboutType.ERROR_ABOUT:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state;
  }
}

export default reducer;