import { format, parseISO } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { Post } from '../../state/actions/post';

export interface CardProps {
  _id?: string,
  title: string,
  previewDesc: string,
  // description: string,
  images: Array<string>,
  createdAt?: Date
}

const Card = ({_id, title, previewDesc, images = [], createdAt, videos}: Post): JSX.Element => {
  const options = {
    // type         : 'loop',
    autoplay     : true,
    pauseOnHover : false,
    resetProgress: false,
  };
  let parsedVideos = videos && videos.length > 0 && videos.map(url => {
    const videoId = url.split("v=")[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  }) || [];
  
  let multimedia = images.concat(parsedVideos);
  const hasNonEmptyString = multimedia && multimedia.length > 0 && multimedia.some(image => image !== "");
  const pattern = /\.(jpg|jpeg|png|svg)$/i;
  return (
    <Link to={`/proyectos/${_id}`} className="w-full font-nova h-auto flex flex-col cursor-pointer group">
      <div className="md:max-w-md h-48 flex-none bg-cover rounded-t-md lg:rounded-b-none lg:rounded-t-md   text-center overflow-hidden relative inline-block w-full">
        {
          hasNonEmptyString ? 
          <>
            {
              multimedia && multimedia.length > 0 && multimedia.map((image, idx) => (
                <>
                  {
                    (pattern.test(image)) ? 
                    <img src={`${image}`} alt={`Image ${idx}`} className="block absolute top-1/2	left-1/2 min-h-full min-w-full -translate-x-1/2 -translate-y-1/2 w-full transition duration-500 group-hover:scale-110" style={{width: "100%",height:"200px", objectFit:"fill", borderRadius:"0.375rem 0.375rem 0 0"}} />
                    : 
                    // <iframe title={image} width="100%" height="200px" src={image} frameBorder="0"></iframe>
                    <img src={`https://img.youtube.com/vi/${image.split("/").slice(-1)[0]}/hqdefault.jpg`} className='block absolute top-1/2	left-1/2 min-h-full min-w-full -translate-x-1/2 -translate-y-1/2 w-full transition duration-500 group-hover:scale-110'/>
                  }
                </>
                
              ))
            }
          </> :
          <img src={`/images/empty.jpeg`} alt={`No Image`} className="border border-y-0" style={{width: "100%",height:"200px", objectFit:"fill", borderRadius:"0.375rem 0.375rem 0 0"}} />
        }
        {/* <img src={images && images.length > 0 ? images[0] : '/images/empty.jpeg'} alt="" className='block absolute top-1/2	left-1/2 min-h-full min-w-full -translate-x-1/2 -translate-y-1/2 w-full transition duration-500 group-hover:scale-110'/> */}
      </div>
      <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal md:max-w-md px-0 font-nova">
        <div className="mb-8">
          <p className="text-sm lg:text-md text-gray-600 flex items-center">
            {format(parseISO(createdAt?.toString()!), "MMMM dd, yyyy")}
          </p>
          <p className="text-black font-nova-semibold font-bold text-xl lg:text-xl mb-1 leading-5">{title}</p>
          <p className="text-grey-darker text-base lg:text-lg leading-5">{previewDesc}</p>
        </div>
      </div>
    </Link>
  )
}

export default Card