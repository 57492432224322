import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./WebsiteLayout";

interface ILayout {
  isFromWebsite?: boolean,
  showNavbar?: boolean
}

const AppLayout = ({isFromWebsite = false, showNavbar = true}: ILayout) => {
    return <>
        {
          !isFromWebsite ? 
          <Sidebar>
            <Outlet />
          </Sidebar> : 
          <Navbar>
            <Outlet />
          </Navbar>
        }
    </>;
};

export default AppLayout;