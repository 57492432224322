import { ContactType } from "../action-types/contact";
import { ContactAction, ContactState } from "../actions/contact";

const initialState: ContactState = {
  contacts: [],
  contact: null,
  loading: true,
  error: null,
}

const reducer = (state: ContactState = initialState, action: ContactAction) => {
  switch(action.type){
    case ContactType.RETRIEVE_ALL_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
        loading: false,
        error: null,
      }
    case ContactType.RETRIEVE_SINGLE_CONTACT:
      return {
        ...state,
        contact: action.payload,
        loading:false,
        error:null
      }
    case ContactType.ADD_CONTACT:
      return {
        ...state,
        contacts: [action.payload, ...state.contacts],
        loading: false,
        error: null
      }
    case ContactType.ASSIGN_FAVORITE:
      return {
        ...state,
        loading: false,
        contacts: state.contacts.map(contact => contact._id === action.payload._id ? contact = action.payload : contact)
      }
    case ContactType.ERROR_CONTACT:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ContactType.DELETE_MESSAGE:
      return {
        ...state,
        loading: false,
        contacts: state.contacts.filter(contact => contact._id !== action.payload)
      }
    default:
      return state;
  }
}

export default reducer;