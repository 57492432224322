import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../state';
import { AuthState } from '../state/actions/auth';

export const PrivateRouter = ({
  component: Component,
  ...rest
}: any) => {
  
  const navigate = useNavigate();
  
  const {user, loading, isAuthenticated} = useSelector((state: RootState) => state.auth) as AuthState;
  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/admin/login');
    }
  }, [loading, isAuthenticated, navigate]);
  return (
    <>
      {!loading && isAuthenticated ? <Component {...rest} /> : null}
    </>
  );
};