import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../components/common/Input';
import CustomHead from '../../components/CustomHead';
import { RootState } from '../../state';
import { auth } from '../../state/action-creators/auth';
import { AuthForm, AuthState } from '../../state/actions/auth';

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const {email, password} = formData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data: AuthState = useSelector((state: RootState) => state.auth);
  const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm<AuthForm>();
  const handleOnSubmit = async (data: AuthForm) => {
    // e.preventDefault();
    console.log("watch: ",data);
    dispatch(auth(data.email, data.password) as any);
  };
  
  useEffect(() => {
    if(data.isAuthenticated){
      navigate(`/admin/posts`);
    }
  },[data.isAuthenticated])
  
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <CustomHead title="Revuelo - Autenticación"/>
      <section className="h-screen w-auto relative">
        <div className="h-full text-gray-800 bg-red-300 bg-teather object-fill bg-center grayscale-300 opacity-100 bg-cover img-banner h-screen">
          <div className="flex xl:justify-center lg:justify-center justify-center items-center flex-wrap h-full g-6 w-full p-5 md:p-0">
            <div className="xl:w-5/12 lg:w-5/12 md:w-8/12 w-full mb-12 md:mb-0 border rounded-md shadow-md bg-white">
              <div className='p-5 md:p-10 m-1 rounded-md'>
                <div className='flex justify-center w-50'>
                  <img src={'/logos/revuelo-main-logo-black.png'} alt="Revuelo Logo" className='w-36 mb-0' />
                </div>
                <form
                  className="flex flex-col items-center my-4 before:flex-1 w-full"
                  method='post'
                  onSubmit={handleSubmit((data) => handleOnSubmit(data))}
                >
                
                  <Input label='Correo Electrónico' errors={errors} name={'email'} register={register} placeholder='Tu email' showRequired={false} registerOptions={{required: 'Este campo es obligatorio'}}/>
                  <Input label='Contraseña' errors={errors} name={'password'} register={register} placeholder='Tu contraseña' showRequired={false} registerOptions={{required: 'Este campo es obligatorio'}}/>
                  <div className="text-center lg:text-left w-full flex justify-center">
                    <button
                      type="submit"
                      className="inline-block px-7 py-3 bg-gray-700 text-white font-normal text-md leading-snug rounded shadow-md hover:bg-gray-600 transition duration-150 ease-in-out w-full md:w-auto"
                      // onClick={handleSubmit}
                    >
                      Ingresar
                    </button>
                  </div>
                </form>
                <div className='text-center my-4 mb-0'>
                  <Link to={'/'} className='text-gray-500 hover:text-gray-600 transition duration-300'>
                    Volver a Revuelo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login