import React from 'react'

interface IProps {
  handleToggle: (evt: any) => void,
  isChecked: boolean
}

const ToggleInput = ({ handleToggle, isChecked }: IProps) => {
  return (
    <div className='bg-gray-200 text-white relative inline-flex items-center p-3 rounded-md my-2 shadow-md'>
      <label className="relative inline-flex items-center cursor-pointer text-white">
        <input type="checkbox" value="" className="sr-only peer" onChange={handleToggle} checked={isChecked ? true : false}/>
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer dark:bg-gray-2 peer-checked:after:translate-x-full peer-checked:after:border-gray-800 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-800 border border-border"></div>
        <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-500">
          {isChecked ? "Deshabilitar Proyecto" : "Habilitar Proyecto"}
        </span>
      </label>
    </div>
  )
}

export default ToggleInput