import React from 'react'
import { Link } from 'react-router-dom'

interface IButtonIcon {
  url?: string,
  icon: JSX.Element,
  onClick?: (evt: any) => void,
  classes?: string
}

const ButtonIcon = ({ url, icon, onClick, classes }: IButtonIcon) => {
  return (
    <Link to={url || ""} className={`text-sm text-xs leading-tight rounded hover:shadow-lg transition duration-500 ease-in-out font-semibold leading-none inline-flex items-center justify-center py-3 px-4 ${(classes) ? classes : 'text-gray-500 bg-gray-100 hover:bg-gray-200'}`} onClick={onClick}>
      {icon}
    </Link>
  )
}

export default ButtonIcon